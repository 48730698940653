import React from "react";
import { PreferenceOption } from "../../../../components";
import { useUser } from "../../../../contexts/user-context";

export const StepOne = () => {
  const options = [
    { title: "Young Man" },
    { title: "Young Lady" },
    { title: "Neutral" },
    { title: "Old Man" },
    { title: "Old Lady" },
  ];

  const { setUserPreferences } = useUser();

  return (
    <>
      <p className="text-2xl text-gray-400 text-center">
        Select your preferred voice type
      </p>
   
      <div className="flex flex-wrap justify-center gap-2 mt-6">
        {options.map(({ title }) => (
          <PreferenceOption
          tag="voiceType"
            value={title}
            key={title}
            onClick={(e) => {
              e.stopPropagation();
              setUserPreferences((previousValues) => ({
                ...previousValues,
                voiceType: title,
              }));
            }}
          />
        ))}
      </div>
    </>
  );
};
