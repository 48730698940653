import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import tipIcon from '../../../../assets/images/tip_icon.png';
import apiClient from '../../../../api/server';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-title {
    margin-bottom: 20px;
    font-family: 'Circular Std Bold' !important;
    font-style: normal;
    font-size: 16px !important;
    color: #272833;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    margin-top: 80px;
    &__mobile {
      display: flex;
      margin-bottom: 2rem;
    }
    &__desktop {
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      &__mobile {
        display: none !important;
      }
      &__desktop {
        display: flex !important;
      }
    }
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .add-achievement-section {
    width: 100%;
    text-align: right;
  }

  .add-item-section {
    width: 100%;
    text-align: center;
  }

  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }

  .checkbox {
    .MuiFormControlLabel-label {
      font-family: 'Circular Std Book' !important;
      font-style: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .achievement-section {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 5%;
    background: rgba(92, 112, 205, 0.01);
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .item-section {
    position: relative;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 4% 5% 5% 5%;
    margin-bottom: 25px;
    background: rgba(92, 112, 205, 0.05);
  }

  .Mui-error {
    border-radius: 4px;
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding: 5px;
  }

  .date-input {
    background-color: #f1f2f5 !important;
    .MuiOutlinedInput-input {
      font-family: Circular Std Book !important;
      width: 100% !important;
      font-size: 16px !important;
      color: rgb(39, 40, 51) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7ed;
    }
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: red !important;
  }

  .remove-text {
    font-size: 14px !important;
    font-family: Circular Std Medium !important;
    margin-top: 3px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;

const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    color: 'rgb(39, 40, 51)',
  },
  fontFamily: 'Circular Std Book !important',
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },

  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book !important',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));

const BootstrapSelectInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '.MuiInputBase-root': {
    fontFamily: 'Circular Std Book !important',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
}));

const Education = React.forwardRef(({ user, back, next, formOnly }, ref) => {
  const { t, i18n } = useTranslation();
  const formRef = useRef();
  const [data, setData] = useState([
    {
      id: '',
      index: 0,
      name: '',
      degree: '',
      fieldOfStudy: '',
      startDate: moment('MM YYYY').toDate(),
      endDate: moment('MM YYYY').toDate(),
      active: false,
      grade: '',
      others: '',
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [fromNav, setFromNav] = useState(false);

  useImperativeHandle(ref, () => ({
    async submit() {
      if (formRef.current) {
        setFromNav(true);
        await formRef.current.handleSubmit();
      }
    },
  }));
  const getData = async () => {
    const response = await apiClient.getEducation(localStorage.getItem("userId"));
    if (response.status) {
      response.data.forEach((item, index) => {
        item.index = index;
        Object.keys(item).forEach((key) => {
          if (item[key] == null) {
            item[key] = '';
          }
        });
      });

      if (response.data.length > 0) {
        setData(response.data);
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const validationSchema = yup.array().of(
    yup.object().shape({
      name: yup.string('Enter your Title').required('Title is required'),
      degree: yup
        .string('Select your Employment Type')
        .required('Employment Type is required'),
      fieldOfStudy: yup
        .string('Enter your Company Name')
        .required('Company Name is required'),
      // startDate: yup
      //   .string('Enter your Start Date')
      //   .required('Start Date is required'),
    })
  );

  const handleFormSubmit = async (values, setSubmitting) => {
    const response = await apiClient.postEducation(localStorage.getItem("userId"), values);

    if (response.status) {
      if (!formOnly) {
        if (fromNav) {
          next(true);
        } else {
          next();
        }
      } else {
        toast.success('Update Successful');
        setSubmitting(false);
      }
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    back();
  };

  const handleAddEducation = (setFieldValue) => {
    const array = formRef.current.values;
    array.push({
      id: '',
      index: array.length,
      name: '',
      degree: '',
      fieldOfStudy: '',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      active: false,
      grade: '',
      others: '',
    });
    setFieldValue(``, array, false);
  };

  const handleDeleteEducation = (index, setFieldValue) => {
    const array = formRef.current.values;
    array.splice(index, 1);
    setFieldValue(``, array, false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  return (
    <MainContent>
      {!isLoading && (
        <Box>
          {!formOnly && (
            <Box>
              <button
                type="button"
                className="skip-button"
                onClick={() => next()}
              >
                {t('Skip This')}
              </button>
              <p className="title">{t('Education')}</p>
              <p className="description">
                {t('Here we want to hear your education history.')}
              </p>
              <Container
                id="tip-section"
                className="tip-section tip-section__mobile"
              >
                <Box className="tip-img-section">
                  <img className="tip-icon" src={tipIcon} alt="bulb icon" />
                </Box>
                <Box className="tip-content-section">
                  <p className="tip-title">{t('Tips to help you')}</p>
                  <p className="tip-description">{t('Education Tip 1.1')}</p>
                </Box>
              </Container>
            </Box>
          )}

          <Container id="form-section" className="form-container">
            <Formik
              innerRef={formRef}
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {values.map((item, index) => (
                    <Box key={`${item.index}`}>
                      <Box className="item-section">
                        <p className="input-title">
                          {t('Education #')}
                          {index + 1}
                        </p>
                        <Box className="input-section">
                          <p className="input-label">{t('Name')}</p>
                          <IconButton
                            aria-label="delete"
                            component="span"
                            disableFocusRipple
                            className="delete-button"
                            onClick={() =>
                              handleDeleteEducation(index, setFieldValue)
                            }
                          >
                            <DeleteIcon />
                            <span className="remove-text">{t('Remove')}</span>
                          </IconButton>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="Name of School"
                              id="text-input"
                              value={item.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].name`}
                              error={
                                getIn(errors, `[${index}].name`) &&
                                getIn(touched, `[${index}].name`)
                              }
                            />
                            {getIn(errors, `[${index}].name`) &&
                            getIn(touched, `[${index}].name`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].name`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">{t('Degree')}</p>
                          <FormControl className="form-container">
                            <Select
                              id="employment-type-select"
                              value={item.degree}
                              onChange={handleChange}
                              input={<BootstrapSelectInput />}
                              displayEmpty
                              name={`[${index}].degree`}
                              error={
                                getIn(errors, `[${index}].degree`) &&
                                getIn(touched, `[${index}].degree`)
                              }
                            >
                              <MenuItem value="" disabled>
                                {t('Degree')}
                              </MenuItem>
                              <MenuItem value="Associate">
                                {t('Associate')}
                              </MenuItem>
                              <MenuItem value="Bachelor's">
                                {t('Bachelors')}
                              </MenuItem>
                              <MenuItem value="Masters">
                                {t('Masters')}
                              </MenuItem>
                              <MenuItem value="PHD/Doctorate">
                                {t('PHD/Doctorate')}
                              </MenuItem>
                            </Select>
                            {getIn(errors, `[${index}].degree`) &&
                            getIn(touched, `[${index}].degree`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].degree`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">{t('Field of Study')}</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="fieldOfStudy"
                              id="text-input"
                              value={item.fieldOfStudy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].fieldOfStudy`}
                              error={
                                getIn(errors, `[${index}].fieldOfStudy`) &&
                                getIn(touched, `[${index}].fieldOfStudy`)
                              }
                            />
                            {getIn(errors, `[${index}].fieldOfStudy`) &&
                            getIn(touched, `[${index}].fieldOfStudy`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].fieldOfStudy`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>

                        <Box className="input-section">
                          <p className="input-label">{t('Date Started')}</p>

                          <FormControl
                            className="form-container"
                            variant="standard"
                            sx={{
                              '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            }}
                          >
                            <MobileDatePicker
                              inputFormat="MM/YYYY"
                              views={['year', 'month']}
                              clearable
                              placeholder="Date Started"
                              value={item.startDate}
                              onChange={(value) =>
                                setFieldValue(`[${index}].startDate`, value)
                              }
                              onBlur={handleBlur}
                              // error={
                              //   getIn(errors, `[${index}].startDate`) &&
                              //   getIn(touched, `[${index}].startDate`)
                              // }
                              name={`[${index}].startDate`}
                              renderInput={(params) => (
                                <TextField
                                  className="date-input"
                                  {...params}
                                  sx={{
                                    '& .Mui-error, .Mui-error::after': {
                                      border: 'none',
                                    },
                                  }}
                                />
                              )}
                            />

                            {getIn(errors, `[${index}].startDate`) &&
                            getIn(touched, `[${index}].startDate`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].startDate`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>
                        <Box className="input-section">
                          <FormControlLabel
                            className="checkbox"
                            control={
                              <Checkbox
                                checked={item.active}
                                onChange={handleChange}
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#5c70cd',
                                  },
                                }}
                                name={`[${index}].active`}
                              />
                            }
                            label={t('I currently study here')}
                          />
                        </Box>
                        {!item.active && (
                          <Box
                            className="input-section"
                            sx={{
                              '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            }}
                          >
                            <p className="input-label">{t('End Date')}</p>

                            <FormControl
                              className="form-container"
                              variant="standard"
                              error={false}
                            >
                              <MobileDatePicker
                                inputFormat="MM/YYYY"
                                clearable
                                views={['year', 'month']}
                                placeholder={t(
                                  'When did you end at this role?'
                                )}
                                value={item.endDate}
                                onChange={(value) =>
                                  setFieldValue(`[${index}].endDate`, value)
                                }
                                onBlur={handleBlur}
                                name={`[${index}].endDate`}
                                error={false}
                                renderInput={(params) => (
                                  <TextField
                                    error={false}
                                    className="date-input"
                                    {...params}
                                    sx={{
                                      '& .Mui-error, .Mui-error::after': {
                                        border: 'none',
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Box>
                        )}

                        <Box className="input-section">
                          <p className="input-label">{t('Grade')}</p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <BootstrapInput
                              placeholder="grade"
                              id="text-input"
                              value={item.grade}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].grade`}
                              error={
                                getIn(errors, `[${index}].grade`) &&
                                getIn(touched, `[${index}].grade`)
                              }
                            />
                            {getIn(errors, `[${index}].grade`) &&
                            getIn(touched, `[${index}].grade`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].grade`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>
                        <Box className="achievement-section">
                          <p className="input-label">
                            {t('Activities and Societies')}
                          </p>
                          <FormControl
                            className="form-container"
                            variant="standard"
                          >
                            <CustomTextArea
                              placeholder={t(
                                'Share any activities and socities you joined or participated in'
                              )}
                              id="custom-textarea-input"
                              multiline
                              rows={3}
                              value={item.others}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`[${index}].others`}
                              error={
                                getIn(errors, `[${index}].others`) &&
                                getIn(touched, `[${index}].others`)
                              }
                            />
                            {getIn(errors, `[${index}].others`) &&
                            getIn(touched, `[${index}].others`) ? (
                              <Box className="error-text">
                                {getIn(errors, `[${index}].others`)}
                              </Box>
                            ) : null}
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  ))}

                  <Box className="add-item-section">
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className="form_next_btn"
                      onClick={() => handleAddEducation(setFieldValue)}
                      startIcon={<AddIcon />}
                    >
                      {t('Add Education')}
                    </Button>
                  </Box>

                  <Box className="button-section">
                    {!isSubmitting && (
                      <Box>
                        {!formOnly && (
                          <button
                            type="button"
                            className="back-button inline"
                            onClick={handleBack}
                          >
                            {t('Back')}
                          </button>
                        )}

                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="form_next_btn inline"
                        >
                          {formOnly ? 'Save' : 'Next Step'}
                        </button>
                      </Box>
                    )}
                    {isSubmitting && <CircularProgress />}
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}
      <Container id="tip-section" className="tip-section tip-section__desktop">
        <Box className="tip-img-section">
          <img className="tip-icon" src={tipIcon} alt="bulb icon" />
        </Box>
        <Box className="tip-content-section">
          <p className="tip-title"> {t('Tips to help you')}</p>
          <p className="tip-description">{t('Education Tip 1.2')}</p>
        </Box>
      </Container>
      {isLoading && (
        <Container className="loading-container">
          <CircularProgress size={60} />
        </Container>
      )}
    </MainContent>
  );
});
Education.displayName = 'Education';
export default Education;
