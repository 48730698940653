const {
  REACT_APP_LINKEDIN_CLIENT_ID,
  // REACT_APP_SERVER_BASE_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env;

export const envConfig = {
  linkedinClientId: REACT_APP_LINKEDIN_CLIENT_ID,
  // serverBaseUrl: REACT_APP_SERVER_BASE_URL,
  // serverBaseUrl: 'https://resumereview-03ed11399994.herokuapp.com/api',
  serverBaseUrl: "https://rezzie-backend.herokuapp.com/api/v1",
  googleClientId: REACT_APP_GOOGLE_CLIENT_ID,
};
