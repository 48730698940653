import React from 'react';
import { useUser } from '../../../contexts/user-context';

export const LoadingScreen = () => {
  const { planPaymentResponse } = useUser();

  return (
    <div className="text-center text-2xl max-w-[360px] mx-auto mb-5">
      <p className="mb-6">Setting up your account</p>
      <p>
        {!planPaymentResponse.message
          ? 'AI is preparing an experience tailored to your preferrences'
          : 'Payment completed'}{' '}
      </p>

      <p className="mt-[300px] font-bold">
        {!planPaymentResponse.message
          ? ''
          : 'Congratulations........'}
      </p>
    </div>
  );
};
