import React from "react";
import { PreferenceOption } from "../../../../components";
import { useUser } from "../../../../contexts/user-context";

export const StepThree = () => {
  const options = [{ title: "New Graduate" }, { title: "Experienced" }];

  const { setUserPreferences } = useUser();

  return (
    <>
      <p className="text-2xl text-gray-400 text-center">
        What will you classify your work level?
      </p>
      <div className="flex flex-col justify-center items-center gap-5 mt-6">
        {options.map(({ title }) => (
          <PreferenceOption
            tag="workLevel"
            value={title}
            key={title}
            onClick={(e) => {
              e.stopPropagation();
              setUserPreferences((previousValues) => ({
                ...previousValues,
                workLevel: title,
              }));
            }}
          />
        ))}
      </div>
    </>
  );
};
