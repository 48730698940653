import React from 'react';
import { useUser } from '../contexts/user-context';

export const PreferenceOption = ({ value, onClick, tag }) => {
  const { userPreferences } = useUser();
  return (
    <button
      type="button"
      className={`rounded-lg shadow text-[20px] text-center px-2 py-1 w-[158px] font-semibold border hover:cursor-pointer duration-200 ${
        userPreferences[tag] === value
          ? 'text-white bg-[#19BCFE] border-[#19BCFE]'
          : 'text-black bg-white border-[#DEDEDE]'
      }`}
      style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      onClick={onClick}
    >
      {value}
    </button>
  );
};
