import React from 'react';
import {
  ResumeAndCoverLetterHomeLayout,
  ProductBox,
} from '../../../components';
import buildLogo from '../../../assets/images/home_icon_build.png';
import searchLogo from '../../../assets/images/home_icon_search.png';
import templateLogo from '../../../assets/images/home_icon_templates.png';



const CoverLetterBuilderHome = () => (
    <>
      <ResumeAndCoverLetterHomeLayout>
        <ProductBox
          link="/coverletter/build/contact"
          icon={buildLogo}
          text="Build a new cover letter"
        />
        <ProductBox
          link="/coverletter/review"
          icon={searchLogo}
          text={
            <>
              View/edit your existing <br />
              resume
            </>
          }
        />
        <ProductBox
          link="/coverletter/templates"
          icon={templateLogo}
          text={
            <>
              Check out our resume <br />
              templates
            </>
          }
        />
      </ResumeAndCoverLetterHomeLayout>
  
    </>
  );

export default CoverLetterBuilderHome;
