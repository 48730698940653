import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';

export const ResumeAndCoverLetterHomeLayout = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      <div className="">
        <Container id="content-section">
          <nav className="text-[20px] sm:text-2xl md:text-[40px] font-semibold flex justify-center">
            <Link
              to="/resume"
              className={pathname === '/resume' ? 'opacity-100' : 'opacity-50'}
            >
              {t('Resume Builder/')}
            </Link>{' '}
            <Link
              to="/coverletter"
              className={`${
                pathname === '/coverletter' ? 'opacity-100' : 'opacity-50'
              }`}
            >
              {t(' Cover Letter Builder')}
            </Link>
          </nav>

          <div className="grid md:grid-cols-3 gap-14 mt-12 md:mt-[145px]">
            {children}
          </div>
        </Container>
      </div>
    </>
  );
};
