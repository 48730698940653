import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  FormControl,
  Box,
  CircularProgress,
  Stack,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import tipIcon from '../../../assets/images/tip_icon.png';
import Layout from '../../layout/Layout';
import apiClient from '../../../api/server';

const _ = require('lodash');

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;
  margin: 0 auto;
  padding: 0 16px;
  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Bold';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }
  .input-label {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }
  .form_next_btn {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;

const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    color: 'rgb(39, 40, 51)',
  },
  fontFamily: ['Circular Std Regular'].join(','),
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const CoverLetterBuilder = () => {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { form } = useParams();
  const [submitElement, setSubmitElement] = useState('icon');

  const [coverLetterData, setCoverLetterData] = useState({
    recipient: '',
    introduction: '',
    supportingEvidence: '',
    summary: '',
    signature: '',
  });
  const buildSteps = [
    {
      step: 0,
      name: 'Recipient',
      fieldName: 'recipient',
      description: 'Who will be the recipient of this letter?',
      maxLength: 50,
      placeholder: 'Fill this space with the recipient name',
      icon: <MailOutlineIcon />,
    },
    {
      step: 1,
      name: 'Introduction',
      fieldName: 'introduction',
      description:
        'In 250 words, tell us a compelling summary of your experiences and interests.',
      maxLength: 250,
      placeholder: 'Fill in this space with an introduction that it compelling',
      icon: <EmojiPeopleOutlinedIcon />,
      tips: (
        <>
          {t('Introduction Tip 1.1')}
          <br />
          <br />
          <em>
            {t('Introduction Tip 1.2')}{' '}
            <strong>{t('Introduction Tip 1.3')}</strong>.{' '}
            {t('Introduction Tip 1.4')}{' '}
            <strong>{t('Introduction Tip 1.5')}</strong>,{' '}
            {t('Introduction Tip 1.6')}
            <strong>{t('Introduction Tip 1.7')}</strong>.{' '}
          </em>
        </>
      ),
    },
    {
      step: 2,
      name: 'Supporting Evidence',
      fieldName: 'supportingEvidence',
      description:
        'In 250 words, tell us a compelling summary of your experiences and interests.',
      maxLength: 250,
      placeholder: 'What have you done or achieved in this role in the past?',
      icon: <FindInPageOutlinedIcon />,
      tips: (
        <>
          {t('Supporting Evidence Tip 1.1')}
          <br /> <br />
          <em>
            {' '}
            {t('Supporting Evidence Tip 1.2')}{' '}
            <strong>{t('Supporting Evidence Tip 1.3')}</strong>.{' '}
            {t('Supporting Evidence Tip 1.4')}{' '}
            <strong>{t('Supporting Evidence Tip 1.5')}</strong>.{' '}
            {t('Supporting Evidence Tip 1.6')}{' '}
            <strong>{t('Supporting Evidence Tip 1.7')}</strong>.
          </em>
        </>
      ),
    },
    {
      step: 3,
      name: 'Summary',
      fieldName: 'summary',
      description:
        'In 250 words, tell us a compelling summary of your experiences and interests.',
      maxLength: 250,
      placeholder: 'Summarise all you have said in the first two paragraphs.',
      icon: <SummarizeOutlinedIcon />,
      tips: (
        <>
          {t('Summary Tip 1.1')}
          <br />
          <br />
          <em>
            {t('Summary Tip 1.2')}
            <strong> {t('Summary Tip 1.3')}</strong>. {t('Summary Tip 1.4')}{' '}
            <strong>{t('Summary Tip 1.5')}</strong>. <br /> <br />
            {t('Summary Tip 1.6')}
            <br /> {t('Summary Tip 1.7')}{' '}
          </em>
        </>
      ),
    },
    {
      step: 4,
      name: 'Signature',
      fieldName: 'signature',
      description: '',
      maxLength: 150,
      placeholder: 'Enter your signature',
      icon: <DriveFileRenameOutlineOutlinedIcon />,
    },
  ];
  const [currentBuildStep, setCurrentBuildStep] = useState(buildSteps[0]);

  const getCoverLetter = async () => {
    const response = await apiClient.getCoverLetter(
      localStorage.getItem('userId')
    );
    if (response.status) {
      setCoverLetterData({
        recipient: response.data.recipient ? response.data.recipient : '',
        introduction: response.data.introduction
          ? response.data.introduction
          : '',
        supportingEvidence: response.data.supportingEvidence
          ? response.data.supportingEvidence
          : '',
        summary: response.data.summary ? response.data.summary : '',
        signature: response.data.signature ? response.data.signature : '',
      });
    } else {
      setCoverLetterData({
        recipient: '',
        introduction: '',
        supportingEvidence: '',
        summary: '',
        signature: '',
      });
    }
  };

  useEffect(() => {
    const buildStep = buildSteps.find((x) => x.fieldName === form);
    if (buildStep) {
      setCurrentBuildStep(buildStep);
    }

    getCoverLetter();
  }, []);

  useEffect(() => {
    history.replace(`${currentBuildStep.fieldName}`);
  }, [currentBuildStep]);

  const recipientSchema = yup.object({
    recipient: yup
      .string('Enter your recipient')
      .max(50, 'Recipient should be a max of 50 characters in length')
      .required('Recipient is required'),
  });

  const introductionSchema = yup.object({
    introduction: yup
      .string('Enter your introduction')
      .test({
        name: 'maxWords',
        test: (value) => value && value.split(/[ ,]+/).length <= 250,
        message: 'Introduction should be a max of 250 words in length',
      })
      .required('Introduction is required'),
  });

  const supportingEvidenceSchema = yup.object({
    supportingEvidence: yup
      .string('Enter your supporting evidence')
      .test({
        name: 'maxWords',
        test: (value) => value && value.split(/[ ,]+/).length <= 250,
        message: 'Supporting evidence should be a max of 250 words in length',
      })
      .required('Supporting evidence is required'),
  });

  const summarySchema = yup.object({
    summary: yup
      .string('Enter your summary')
      .test({
        name: 'maxWords',
        test: (value) => value && value.split(/[ ,]+/).length <= 250,
        message: 'Summary should be a max of 250 words in length',
      })
      .required('Summary is required'),
  });

  const signatureSchema = yup.object({
    signature: yup
      .string('Enter your signature')
      .test({
        name: 'maxWords',
        test: (value) => value && value.split(/[ ,]+/).length <= 150,
        message: 'Signature should be a max of 150 words in length',
      })
      .required('Signature is required'),
  });

  const validationSchemaArray = [
    recipientSchema,
    introductionSchema,
    supportingEvidenceSchema,
    summarySchema,
    signatureSchema,
  ];

  const handleFormSubmit = async (values, setSubmitting) => {
    let model = _.cloneDeep(values);
    switch (currentBuildStep.step) {
      case 0:
        model = _.cloneDeep(_.pick(model, ['recipient']));
        break;
      case 1:
        model = _.cloneDeep(_.pick(model, ['introduction']));
        break;
      case 2:
        model = _.cloneDeep(_.pick(model, ['supportingEvidence']));
        break;
      case 3:
        model = _.cloneDeep(_.pick(model, ['summary']));
        break;
      case 4:
        model = _.cloneDeep(_.pick(model, ['signature']));
        break;
      default:
        break;
    }

    const response = await apiClient.createCoverLetter(
      localStorage.getItem('userId'),
      model
    );
    if (response.status) {
      setSubmitting(false);
      switch (currentBuildStep.step) {
        case 0:
        case 1:
        case 2:
        case 3:
          if (submitElement === 'btn') {
            setCurrentBuildStep(buildSteps[currentBuildStep.step + 1]);
          }
          break;
        case 4:
          history.push(`/coverletter/review`);
          break;
        default:
          break;
      }
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleBack = () => {
    if (currentBuildStep.step !== 0) {
      setCurrentBuildStep(buildSteps[currentBuildStep.step - 1]);
    }
  };

  const routeToStep = async (step) => {
    setCurrentBuildStep(buildSteps[step]);
  };

  return (
    <MainContent>
      {buildSteps.map(
        (item) =>
          item.step === currentBuildStep.step && (
            <Box key={`${item.step}`}>
              <Container id="form-section" className="form-container">
                <Formik
                  enableReinitialize
                  validationSchema={
                    validationSchemaArray[currentBuildStep.step]
                  }
                  initialValues={coverLetterData}
                  onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                          sx={{
                            padding: '10px',
                            borderRadius: '10px',
                            border: '1px solid #e4e4e4',
                            flexWrap: 'nowrap',
                            marginBottom: '40px',
                            overflowX: 'scroll',
                            width: { sm: 'fit-content' },
                          }}
                          className="navigation"
                        >
                          {buildSteps.map((i, index) => (
                            <Box
                              onClick={async (e) => {
                                if (currentBuildStep.step !== 4)
                                  // submit if only the user is on the last step... Because the last
                                  // step routes to '/coverletter/review' when submitted.
                                  await handleSubmit();

                                routeToStep(i.step);
                              }}
                              sx={{
                                textAlign: 'center',
                                cursor: 'pointer',
                              }}
                              key={`${i.step}`}
                            >
                              {i.step === currentBuildStep.step ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignis: 'center',
                                  }}
                                >
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    sx={{
                                      color: '#FFFFFF',
                                      backgroundColor: '#5c70cd',
                                      width: '55px',
                                      height: '55px',
                                    }}
                                  >
                                    {i.icon}
                                  </IconButton>
                                  <Box
                                    sx={{
                                      marginLeft: '15px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: 'Circular Std Medium',
                                        fontSize: { xs: '10px', sm: '12px' },
                                        letterSpacing: '0em',
                                        marginBottom: '5px',
                                        color: '#5C70CD',
                                        textAlign: 'left',
                                      }}
                                    >
                                      Step {index + 1}/{buildSteps.length}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Circular Std Medium',
                                        fontSize: { xs: '10px', sm: '12px' },
                                        letterSpacing: '0em',
                                        marginBottom: '5px',
                                        color: '#131936',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {i.name}
                                    </Typography>
                                  </Box>
                                </Box>
                              ) : (
                                <IconButton
                                  disableFocusRipple
                                  disableRipple
                                  sx={{
                                    color: '#c0c7e3',
                                  }}
                                >
                                  {i.icon}
                                </IconButton>
                              )}
                              {item.step !== currentBuildStep.step && (
                                <Box sx={{ cursor: 'pointer' }}>
                                  <IconButton
                                    disableFocusRipple
                                    disableRipple
                                    sx={{
                                      paddingLeft: {
                                        xs: '3px !important',
                                        sm: '8px !important',
                                      },
                                      color: '#c0c7e4',
                                    }}
                                  >
                                    {item.icon}
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          ))}
                        </Stack>
                      </Box>

                      <p className="title">{item.name}</p>
                      <p className="description">{item.description}</p>
                      <FormControl
                        className="form-container"
                        variant="standard"
                      >
                        <CustomTextArea
                          placeholder={currentBuildStep.placeholder}
                          id="custom-textarea-input"
                          multiline
                          rows={8}
                          value={values[currentBuildStep.fieldName]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={currentBuildStep.fieldName}
                        />
                        {errors[currentBuildStep.fieldName] &&
                        touched[currentBuildStep.fieldName] ? (
                          <div>{errors[currentBuildStep.fieldName]}</div>
                        ) : null}

                        {currentBuildStep.fieldName !== 'recipient' &&
                          values[currentBuildStep.fieldName] &&
                          values[currentBuildStep.fieldName].split(/[ ,]+/)
                            .length < currentBuildStep.maxLength && (
                            <span className="words-left">
                              {currentBuildStep.maxLength -
                                values[currentBuildStep.fieldName].split(
                                  /[ ,]+/
                                ).length}{' '}
                              words left
                            </span>
                          )}
                        {currentBuildStep.fieldName !== 'recipient' &&
                          values[currentBuildStep.fieldName] &&
                          values[currentBuildStep.fieldName].split(/[ ,]+/)
                            .length >= currentBuildStep.maxLength && (
                            <span className="words-left">
                              Max word limit reached
                            </span>
                          )}
                        {currentBuildStep.fieldName === 'recipient' &&
                          values[currentBuildStep.fieldName] &&
                          values[currentBuildStep.fieldName].length <
                            currentBuildStep.maxLength && (
                            <span className="words-left">
                              {currentBuildStep.maxLength -
                                values[currentBuildStep.fieldName].length}{' '}
                              characters left
                            </span>
                          )}

                        {currentBuildStep.fieldName === 'recipient' &&
                          values[currentBuildStep.fieldName] &&
                          values[currentBuildStep.fieldName].length >=
                            currentBuildStep.maxLength && (
                            <span className="words-left">
                              Max character limit reached
                            </span>
                          )}
                      </FormControl>

                      <Box className="button-section">
                        {!isSubmitting && (
                          <Box>
                            {currentBuildStep.step !== 0 && (
                              <button
                                type="button"
                                className="back-button inline"
                                onClick={handleBack}
                              >
                                Back
                              </button>
                            )}
                            <button
                              type="submit"
                              onClick={() => {
                                setSubmitElement('btn');
                                handleSubmit();
                              }}
                              className="form_next_btn inline"
                            >
                              Next Step
                            </button>
                          </Box>
                        )}
                        {isSubmitting && <CircularProgress />}
                      </Box>
                    </form>
                  )}
                </Formik>
                {item.tips && (
                  <Container id="tip-section" className="tip-section">
                    <Box className="tip-img-section">
                      <img className="tip-icon" src={tipIcon} alt="" />
                    </Box>
                    <Box className="tip-content-section">
                      <p className="tip-title">Tips to help you</p>
                      <p className="tip-description">{item.tips}</p>
                    </Box>
                  </Container>
                )}
              </Container>
            </Box>
          )
      )}
    </MainContent>
  );
};

export default CoverLetterBuilder;
