import React from 'react';
import { Button } from '../../../../components';
import { useUser } from '../../../../contexts/user-context';
import { CoinsImg, TickIcon, CoinsImg1 } from '../../../../assets';

const PlanFeature = ({ children }) => (
  <div className="flex items-start gap-1 mb-3 leading-[18px]">
    <img className="mt-1" src={TickIcon} alt="detail" width={15} height={13} />
    <p className="font-thin text-base">{children}</p>
  </div>
);

export const StepFour = () => {
  const { nextStep, previousStep } = useUser();
  return (
    <div className='relative pb-20'>
      <p className="text-2xl  tracking-tighter text-center mb-6 opacity-70">
        Select preferred payment plan
      </p>
      <p className="text-2xl tracking-tighter text-center mb-16">
        Choose the plan that suits you best
      </p>
      <img
        className="scale-x-[-1] absolute z-[-1] -left-20"
        src={CoinsImg1}
        alt="coins"
        height={165}
        width={285}
      />
      <div className="border-4 border-[#9747FF] rounded-xl px-4 pt-3 pb-5 max-w-[262px] bg-white ml-4">
        <p className="text-[#9747FF] text-xl font-bold text-center mb-2">
          Yearly
        </p>
        <p className="text-center text-[40px] font-bold text-black border-b border-[#0000004A]">
          ₦10,000
        </p>
        <div className="mt-4">
          <PlanFeature>2 months free full access</PlanFeature>
          <PlanFeature>Access to AI career coach</PlanFeature>
          <PlanFeature>
            Access to Resume reviewer, Career tracking & feedback
          </PlanFeature>
          <PlanFeature>Early access to new features</PlanFeature>
        </div>
        <button
          type="button"
          className="bg-[#9747FF] rounded-lg text-lg text-[#FFFFFFC9] px-10 py-1 font-semibold block mx-auto mt-10"
          onClick={nextStep}
        >
          Choose Plan
        </button>
      </div>
      <img
        className="absolute z-[-1] -right-20 bottom-36"
        src={CoinsImg}
        alt="coins"
        height={165}
        width={285}
      />

      <Button className="mt-32 mx-auto" variant="outline" onClick={previousStep}>
        Back
      </Button>
    </div>
  );
};
