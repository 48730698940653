import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import apiClient from '../../../api/server';
import CoverLetterSample1 from '../../../assets/images/coverLetterSamples/cover-letter-sample-1.png';
import CoverLetterSample2 from '../../../assets/images/coverLetterSamples/cover-letter-sample-2.png';
import CoverLetterSample3 from '../../../assets/images/coverLetterSamples/cover-letter-sample-3.png';

const CoverLetterTemplateViewer = ({ open, close, templateId }) => {
  const [image, setImage] = useState('');

  const templates = [
    {
      id: 1,
      src: CoverLetterSample1,
    },
    {
      id: 2,
      src: CoverLetterSample2,
    },
    {
      id: 3,
      src: CoverLetterSample3,
    },
  ];

  // const getCoverLetterPreview = async () => {
  //   const response = await apiClient.getCoverLetterPreview(localStorage.getItem("userId"));

  //   if (response.status) {
  //     console.log(response);
  //     setImage(response.data);
  //   } else {
  //     console.log(response);
  //   }
  // };

  useEffect(() => {
    setImage(templates[templateId - 1].src);
  }, []);

  return (
    <Dialog open={open} keepMounted onClose={close}>
      <DialogTitle sx={{ marginBottom: 5 }}>
        {image && (
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {image && (
          <Box
            sx={{
              maxWidth: 900,
            }}
          >
            <img style={{ width: '100%' }} src={image} alt="" />
          </Box>
        )}
        {!image && <CircularProgress />}
      </DialogContent>
    </Dialog>
  );
};

export default CoverLetterTemplateViewer;
