import React from 'react';
import Interpretation from './Interpretation';
import Acknowledgement from './Acknowledgement';
import UserAccounts from './UserAccounts'
import Content from './Content';
import Copyright from './Copyright';
import IntellectualProperty from './IntellectualProperty';
import { PageContainer, PageHeading, PageSubheading, Paragraph } from './styles';
import Feedback from './Feedback';
import Termination from './Termination';
import Disclaimer from './Disclaimer';
import Severability from './Severability';

export default function TermsAndConditions() {

  return (
    <>
      <PageContainer>
        <PageHeading>Terms and Conditions</PageHeading>
        <PageSubheading>Terms and Conditions (&quot;Terms&quot;)</PageSubheading>
        <Paragraph>
          Our Terms and Conditions were last updated on January 14, 2022
        </Paragraph>
        <Paragraph>
          Please read these terms and conditions carefully before using Our
          Service.
        </Paragraph>

        {/* Page sections */}
        <Interpretation />
        <Acknowledgement />
        <UserAccounts />
        <Content />
        <Copyright />
        <IntellectualProperty />
        <Feedback />
        <Termination />
        <Disclaimer />
        <Severability />
      </PageContainer>
    </>
  );
}
