import React from 'react';
import { AiOutlineSwap } from 'react-icons/ai';
import { RiDiscountPercentFill } from 'react-icons/ri';
import { Button } from '../../../../components';
import { useUser } from '../../../../contexts/user-context';

const SummaryItem = ({ title, value, total }) => (
  <div
    className={`flex justify-between ${
      total ? 'font-medium text-[22px]' : 'font-thin text-xl'
    }`}
  >
    <span>{title}</span>
    <span>{value}</span>
  </div>
);

const PaymentMethod = ({ className, text, icon }) => (
  <button
    type="button"
    className={`border py-2 px-4 rounded-lg flex justify-between items-center ${className}`}
  >
    {text} {icon}
  </button>
);

const CardInput = ({ label }) => (
  <div>
    <label>{label}</label>
    <input type="text" className="bg-[#F1F2F5] rounded-lg w-full" />
  </div>
);

export const StepFive = () => {
  const { previousStep, nextStep, payForCareerCoachPlan } = useUser();

  return (
    <>
      <p className="text-2xl pb-1 opacity-70 border-b border-[#00000026]">
        Order Summary
      </p>
      <div className="grid gap-1 mt-2 text-[#0F0F0F] mb-11">
        <SummaryItem title="Monthly Package" value="N1,500.00" />
        <SummaryItem title="Sub-total" value="N1,500.00" />
        <SummaryItem title="Tax" value="N50.00" />
        <SummaryItem title="Total" total value="N1,550.00" />
      </div>
      <p className="text-2xl pb-1 opacity-70 border-b border-[#00000026]">
        Payment method
      </p>
      <div className="grid gap-5 text-2xl">
        <PaymentMethod
          className="border-[#FFB6B6]"
          text="Pay via bank transfer"
          icon={<AiOutlineSwap size={30} />}
        />
        <PaymentMethod
          className="border-[#09CF82]"
          text="Use Gift code/voucher"
          icon={<RiDiscountPercentFill size={30} color="#09CF82" />}
        />
        <div className="p-4 border border-[#00000060] rounded-lg text-base">
          <p className="text-[20px]">Card Information</p>
          <div className="mt-4 grid gap-3">
            <CardInput label="Name on card" />
            <CardInput label="Card No" />
            <div className="grid grid-cols-2 gap-4">
              <CardInput label="Expiry date" />
              <CardInput label="CVV" />
            </div>
          </div>
          <div className="flex gap-1 items-center mt-5">
            <input type="checkbox" /> Save information for next time
          </div>
        </div>

        <div className="flex gap-4 pb-10">
          <Button className="grow" onClick={payForCareerCoachPlan} size="sm">
            Pay
          </Button>
          <Button className="grow" variant="outline" onClick={previousStep} size="sm">
            Back
          </Button>
        </div>
      </div>
    </>
  );
};
