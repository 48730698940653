import React from 'react';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { CoverDiv, MoreDescription } from '../../screens/auth/styles';

const AuthLayout = ({ t }) => (
  <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={12} md={6}>
    <CoverDiv>
      <div className='max-w-[600px] ml-auto'>
      <p className="cover-caption">{t('Login Header')}</p>
      <div className="cover-description">
        <p>
          {t('Join us')}
          <br />
          <br />
          {t(
            'Discover our comprehensive toolkit designed to make your job applications easier, faster and truly outstanding. Our platform offers professionally designed resume and cover letter builders, plus an AI-powered resume review tool that provides actionable feedback to help you correct mistakes and polish your presentation'
          )}
        </p>
        <p className="text-white">
          Over 1,000 professionals have already transformed their applications
          with our templates & tools-----And you can too!!!!!
        </p>
      </div>
      <MoreDescription>
        <span>💼&nbsp;&nbsp;&nbsp;{t('Like Professionals')}</span>

        <br />
        <br />
        <span>
          🚀&nbsp;&nbsp;&nbsp;{t('Tools')} <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t('Including yours')}
        </span>
        <br />
        <br />
        <span>😉&nbsp;&nbsp;&nbsp;{t('Recruitment team')}</span>
      </MoreDescription>
      </div>
    </CoverDiv>
  </Grid>
);

export default withTranslation()(AuthLayout);
