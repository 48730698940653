import React from 'react';
import { Button } from '../../../components';
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from './steps';
import { useUser } from '../../../contexts/user-context';

import { OneRobosImg } from '../../../assets';

export const UnpaidPlan = () => {
  const { preferenceStep, previousStep, nextStep } = useUser();

  return (
    <section className="md:max-w-[430px] mx-auto relative">
      <div className="px-6 ">
        {preferenceStep <= 3 && (
          <>
            <p className="text-xl text-center mb-6 opacity-70">
              This will only take a moment
            </p>
            <p className="text-xl text-center mb-16">
              Go through this process to help the AI tool personalize your
              experience.
            </p>
          </>
        )}

        <div className="relative">
          <img
            className={`absolute z-[-1] -left-12 top-12 ${
              preferenceStep > 3 && 'hidden'
            }`}
            src={OneRobosImg}
            alt="robot"
            width={142}
            height={228}
            loading="lazy"
          />
          {preferenceStep === 1 && <StepOne />}
          {preferenceStep === 2 && <StepTwo />}
          {preferenceStep === 3 && <StepThree />}
          {preferenceStep === 4 && <StepFour />}
          {preferenceStep === 5 && <StepFive />}
        </div>

        {preferenceStep < 4 && (
          <div className="grid gap-4 fixed bottom-16 left-1/2 -translate-x-1/2">
            <Button onClick={nextStep}>Next</Button>
            <Button variant="outline" onClick={previousStep}>
              Back
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};
