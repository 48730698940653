import React, { useState, createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { io } from 'socket.io-client';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useAuth } from './auth-context';

const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
  const history = useHistory();

  const { userDetails } = useAuth();

  const [userResume, setUserResume] = useState(null);
  const [reviewResults, setReviewResults] = useState(null);
  const [loadingResults, setLoadingResults] = useState(false);

  const [jobDescriptionText, setJobDescriptionText] = useState('');
  const [jobDescriptionFile, setJobDescriptionFile] = useState(null);

  const [uploadedResumeParsedData, setUploadedResumeParsedData] =
    useState(null);

  const [userPreferences, setUserPreferences] = useState({
    voiceType: '',
    personality: '',
    workLevel: '',
  });

  const [preferenceStep, setPreferenceStep] = useState(1);
  const [planPaymentResponse, setPlanPaymentResponse] = useState({
    loading: false,
    message: '',
    success: false,
  });

  const [socket, setSocket] = useState(null);

  const isValidUrl = (str) => {
    const pattern =
      /^(https?:\/\/.{1,}\.[a-zA-Z]{2,}|www\..{1,}\.[a-zA-Z]{2,}|[\w-]+\.[a-zA-Z]{2,})([/?#].*)?$/;
    return pattern.test(str);
  };

  const nextStep = () => {
    setPreferenceStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    if (preferenceStep === 1) {
      history.push('/careercoach');
    } else {
      setPreferenceStep((prevStep) => prevStep - 1);
    }
  };

  useEffect(() => {
    if (userDetails) {
      const { id: userId } = userDetails;
      const newSocket = io('https://resumereview-03ed11399994.herokuapp.com', {
        transports: ['websocket'],
        query: { userId },
      });

      setSocket(newSocket);
    }

    return () => {
      socket?.disconnect();
    };
  }, [userDetails]);

  useEffect(() => {
    if (socket) {
      const { id: userId } = userDetails;
      socket.emit('joinRoom', userId);
      // socket.on('connect', () => {
      //   console.log('Connected to WebSocket');
      // });

      // socket.on('jobStatus', (data) => {
      //   console.log('Job status update:', data);
      // });

      socket.on('reviewCompleted', (data) => {
        setReviewResults(data?.analysis);
        setLoadingResults(false);
      });

      socket.on('reviewFailed', (data) => {
        toast.error(`Review failed: ${data.error}`);
        setLoadingResults(false);
      });
    }
  }, [socket]);

  const handleJobDescriptionPaste = async () => {
    const pastedText = await navigator.clipboard.readText();
    setJobDescriptionText(pastedText);
  };

  const startReview = async () => {
    const formData = new FormData();
    formData.append('resume', userResume);
    if (jobDescriptionFile) {
      formData.append('jobDescriptionFile', jobDescriptionFile);
    }

    if (isValidUrl(jobDescriptionText)) {
      formData.append('jobDescriptionUrl', jobDescriptionText.trim());
    }

    try {
      setLoadingResults(true);

      await axios.post(
        `https://resumereview-03ed11399994.herokuapp.com/api/resume/${
          jobDescriptionText ? 'job-description' : 'general'
        }-review`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('rezzie_token')}`, // Include token in the header
          },
        }
      );
    } catch (error) {
      toast.error(error?.response?.data?.error);
      setLoadingResults(false);
    }
  };

  const clearResume = () => {
    setUserResume(null);
    setJobDescriptionText('');
    setReviewResults(null);
  };

  const payForCareerCoachPlan = () => {
    setPlanPaymentResponse((prev) => ({ ...prev, loading: true }));

    setTimeout(() => {
      setPlanPaymentResponse((prev) => ({
        ...prev,
        message: 'Payment completed',
      }));
    }, 1500);

    setTimeout(() => {
      setPlanPaymentResponse((prev) => ({
        ...prev,
        success: true,
      }));
    }, 3000);

    setTimeout(() => {
      history.push('/careercoach');
    }, 4000);
  };

  const generatePdfFromHtml = () => {
    // Select the HTML element to convert to PDF
    // const element = document.getElementById('test-script');

    // // Use html2canvas to capture the element as an image
    // html2canvas(element, {
    //   useCORS: true, // Allow cross-origin images
    //   scale: 2, // Increase resolution for better quality
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png'); // Convert canvas to image data

    //   // Create a new PDF instance
    //   const pdf = new JsPDF('p', 'mm', 'a4'); // 'p' for portrait, 'a4' for page size

    //   // Calculate dimensions for the PDF
    //   const imgWidth = 190; // Max width for A4
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

    //   // Add the image to the PDF
    //   pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

    //   // Save the PDF
    //   pdf.save('download.pdf');
    // });
  };

  const handleUserResumeChange = (e) => {
    if (!e.target.files) {
      return;
    }
    setUserResume(e.target.files[0]);
  };

  return (
    <UserContext.Provider
      value={{
        loadingResults,
        nextStep,
        payForCareerCoachPlan,
        planPaymentResponse,
        preferenceStep,
        previousStep,
        reviewResults,
        setPlanPaymentResponse,
        setReviewResults,
        setUserPreferences,
        setUserResume,
        startReview,
        userPreferences,
        userResume,
        clearResume,
        handleUserResumeChange,
        jobDescriptionText,
        jobDescriptionFile,
        setJobDescriptionFile,
        setJobDescriptionText,
        isValidUrl,
        handleJobDescriptionPaste,
        generatePdfFromHtml,
        uploadedResumeParsedData,
        setUploadedResumeParsedData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
