import React from 'react';

export const Button = ({
  children,
  className,
  onClick,
  size = "lg",
  type = "button",
  variant,
}) => (
  <button
    type={type === "submit" ? "submit" : "button"} // Trivial ternary expression
    className={`${className} text-[22px] ${
      variant === 'outline'
        ? 'border-2 bg-white text-[#29BFFF] border-[#29BFFF]'
        : 'border-[0.5px] bg-[#19BCFE] text-[#FFFFFFC9]'
    }   rounded-lg block
 ${size === "lg" && "min-w-[195px] px-10 py-1 "}`}
    onClick={onClick}
  >
    {children}
  </button>
);