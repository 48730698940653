import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import { ChevronDownIcon, RezzieLogo } from '../../assets';
import Container from '../Container';
import { useAuth } from '../../contexts/auth-context';

function Navbar() {
  const { t } = useTranslation();
  const [openProducts, setOpenProducts] = useState(false);
  const { pathname } = useLocation();
  const { isLoggedIn, handleLogout } = useAuth();

  useEffect(() => {
    if (openProducts) {
      setOpenProducts(false);
    }
  }, [pathname]);

  return (
    <Box
      as="nav"
      position="fixed"
      top="0"
      left={0}
      padding="16px"
      sx={{
        background: '#fff',
        boxShadow: '0px 4px 4px #90888840',
      }}
      width="100%"
      fontFamily="Quicksand"
      fontSize={['0.7rem', '1rem']}
      zIndex={5}
    >
      <Container className="flex justify-between items-center w-full">
        <Link to="/">
          <img
            className="w-[80px]"
            src={RezzieLogo}
            alt="logo"
            width={55}
          />
        </Link>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            '& .navlink': {
              '&:not(:first-of-type)': {
                ml: [2, 2, 4],
              },
            },
          }}
        >
          <Box
            position="relative"
            sx={{
              mr: [2],
              '& img': {
                transition: '0.3s',
                rotate: openProducts ? '180deg' : '0deg',
              },
            }}
          >
            <Typography
              display="flex"
              gap={0.5}
              fontSize={['0.7rem', '1rem']}
              onClick={() => setOpenProducts((prev) => !prev)}
            >
              Products
              <img width={10} src={ChevronDownIcon} alt="open products" />
            </Typography>
            <Box
              position="absolute"
              whiteSpace="nowrap"
              top="30px"
              left={['-60px']}
              zIndex={10}
              sx={{
                background: '#fff',
                border: '1px solid #eee',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
                transformOrigin: 'top',
                transform: `scaleY(${openProducts ? '1' : '0'})`,
                boxShadow: "0 4px 4px #90888840"
              }}
            >
              <Link to="/resume" className="block px-4 py-2">
                Resume &amp; Cover Letter Builder
              </Link>
              <Link to="/resumereview" className="block px-4 py-2 m-0">
                AI Resume Review
              </Link>
            </Box>
          </Box>

          <Link className="navlink " to="/resources">
            {' '}
            {t('Resource center')}
          </Link>
          <a className="navlink hidden md:block" href="mailto:info@rezzie.co">
            {t('Contact us')}
          </a>
          {isLoggedIn ? (
            <button
              type="button"
              className="border border-[#00000080] px-2 lg:px-4 lg:py-1 py-0.5 rounded-md ml-3 lg:ml-6"
              onClick={handleLogout}
            >
              Sign Out
            </button>
          ) : (
            <>
              <Link
                className="text-[#29BFFF] navlink"
                to="/resumeandcoverletterbuilder/login"
              >
                Sign In
              </Link>
              <Link
                className="bg-[#29BFFF] text-white px-2 lg:px-4 lg:py-1 py-0.5 rounded-sm navlink"
                to="/resumeandcoverletterbuilder/signup"
              >
                Sign Up
              </Link>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default Navbar;
