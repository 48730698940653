import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  HomeHeaderImg,
  ResumeReviewPlaceholder,
  RobotWithNote,
  TwoRobotsImg,
} from '../../assets';
import Container from '../../components/Container';
import { useAuth } from '../../contexts/auth-context';

const ProductTile = ({
  className,
  img,
  title,
  description,
  linkText,
  route,
}) => {
  const { isLoggedIn, setRouteAfterAuth } = useAuth();
  return (
    <div
      className={`flex flex-col md:flex-row md:items-center md:gap-[122px] ${className}`}
    >
      <img
        className="grow w-full md:max-w-[50%]"
        src={img}
        alt="Resume Review"
        loading="lazy"
      />
      <div className="grow">
        <div className="text-center md:text-left">
          <h6 className="font-semibold text-2xl mt-4 mb-3">{title}</h6>
          <p className="text-[20px]">{description}</p>
        </div>

        {linkText ? (
          <Link
            onClick={() => setRouteAfterAuth(route)}
            to={isLoggedIn ? route : '/resumeandcoverletterbuilder/login'}
            className="bg-[#19BCFE] text-white text-center font-thin rounded-lg w-[195px] block text-[22px] mx-auto md:mx-0 py-1 mt-6"
          >
            {linkText}
          </Link>
        ) : (
          <div className="bg-gray-300 text-white text-center font-thin rounded-lg w-[195px] block text-[22px] mx-auto md:mx-0 py-1 mt-6">
            Coming soon
          </div>
        )}
      </div>
    </div>
  );
};

function Homepage() {
  const { t } = useTranslation();

  return (
    <div className="px-4 pt-20">
      <Container>
        <header className="text-center max-w-[950px] mx-auto">
          <div>
            <p className="font-bold text-[41px] md:text-[56px] mb-6 leading-[1.2]" style={{ textShadow: '0 8px 5px rgba(0, 0, 0, 0.25)' }}>
              We are a HR tech startup with a focus on{' '}
              <span className="text-[#29BFFF]">cruelty free hiring.</span>
            </p>
            <p className="text-[20px] max-w-[650px] mx-auto">{t('Leveraging Best')}</p>
            <img
              className="homepage-img w-full max-w-[550px] mx-auto mt-4"
              src={HomeHeaderImg}
              alt='home'
            />
          </div>
        </header>
        <section className="pt-20">
          <h4 className="font-semibold text-center text-[20px] md:text-[32px]">
            Explore our range of top-notch applicant tools and hiring software
            designed to make your hiring experience seamless & hassle free
          </h4>
          <div className="grid gap-20 md:gap-40 lg:gap-[250px] mt-16">
            <ProductTile
              className="md:flex-row-reverse"
              img={ResumeReviewPlaceholder}
              title="Resume &amp; CV Builder"
              description="Our all-in-one Resume & Cover Letter Builder offers professionally designed templates, intuitive tools and proven tips to help you craft winning resumes and compelling cover letters tailored to diverse industries."
              linkText="Try for free"
              route="/resume"
            />

            <ProductTile
              img={RobotWithNote}
              smallImg
              title="AI Resume Reviewer"
              description="Elevate your professional profile with our AI-powered resume review tool. Our system evaluates your CV and provides real-time and actionable feedback to help you correct mistakes and polish your presentation."
              linkText="Try it now"
              route="/resumereview"
            />
            <ProductTile
              className="md:flex-row-reverse"
              img={TwoRobotsImg}
              title="AI Career Coach"
              description="Let us help improve your hiring chances by speaking with our personalised Artificial Intelligence driven career coach"
            />
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Homepage;
