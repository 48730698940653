import React from "react";
import { PreferenceOption } from "../../../../components";
import { useUser } from "../../../../contexts/user-context";

export const StepTwo = () => {
  const options = [
    { title: "Supportive" },
    { title: "Formal" },
    { title: "Empathetic" },
    { title: "Sympathetic" },
    { title: "Casual" },
    { title: "Hyper" },
  ];

  const { setUserPreferences } = useUser();

  return (
    <div>
      <p className="text-2xl text-gray-400 text-center">
        Select your preferred speaking personality
      </p>
      <div className="flex flex-wrap justify-center gap-2 mt-6 ml-10">
        {options.map(({ title }) => (
          <PreferenceOption
            tag="personality"
            value={title}
            key={title}
            onClick={(e) => {
              e.stopPropagation();
              setUserPreferences((previousValues) => ({
                ...previousValues,
                personality: title,
              }));
            }}
          />
        ))}
      </div>
    </div>
  );
};
