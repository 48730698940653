import React from 'react';
import { useUser } from '../../../contexts/user-context';
import { UnpaidPlan } from './Unpaid';
import { LoadingScreen } from './LoadingScreen';

export const CareerCoachPreferences = () => {
  const { planPaymentResponse } = useUser();

  return (
    <>
      {!planPaymentResponse.loading && !planPaymentResponse.message && (
        <UnpaidPlan />
      )}
      {planPaymentResponse.loading && <LoadingScreen />}
    </>
  );
};
