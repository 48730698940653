import React from 'react';
import styled from 'styled-components';

const MainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = ({ children }) => <MainContent>{children}</MainContent>;

export default Wrapper;
