import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import OnboardingTopNav from '../components/OnboardingNavigation';
import ImportantLinks from '../components/ImportantLinks';
import SaveandContinue from '../components/SaveandContinue';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;
`;

/* Onboarding Layout */
const Wrapper = ({ children }) => (
  <>
    <Helmet>
      <title>Rezzie</title>
    </Helmet>
    <MainContent>
      <OnboardingTopNav />
      {children}
      <ImportantLinks />
      <SaveandContinue />
    </MainContent>
  </>
);

export default Wrapper;
