import React, { useState, createContext, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiClient from '../api/server';

const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('rezzie_token')
  );

  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('user')) ?? null);

  const [loadingAuth, setLoadingAuth] = useState(false);
  const [routeAfterAuth, setRouteAfterAuth] = useState('/');

  useEffect(() => {
    const token = localStorage.getItem('rezzie_token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogin = async (payload) => {
    setLoadingAuth(true);
    try {
      const res = await apiClient.login(payload);
      if (!res?.status) {
        toast.error(res?.message);
      } else {
        localStorage.setItem('rezzie_token', res.data.token);
        localStorage.setItem('userId', res.data.user.id);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        localStorage.setItem('login_time', Date.now().toString());
        setUserDetails(res.data.user);
        setIsLoggedIn(true);
        history.push(routeAfterAuth);
      }
    } catch (error) {
      if (typeof error.data.error === 'string') {
        toast.error(error.data.error);
      } else {
        toast.error(error.data.error[0]);
      }
      console.log('in action login error', error);
    } finally {
      setLoadingAuth(false);
    }
  };

  const handleRegister = async (payload) => {
    setLoadingAuth(true);
    try {
      const res = await apiClient.register(payload);

      if (!res?.status) {
        toast.error(res?.message);
      } else {
        localStorage.setItem('rezzie_token', res.data.token);
        localStorage.setItem('userId', res.data.user.id);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        localStorage.setItem('login_time', Date.now().toString());
        setUserDetails(res.data.user);
        setIsLoggedIn(true);
        toast.success(res?.message);
        setTimeout(() => {
          history.push(routeAfterAuth);
        }, 1000);
      }
    } catch (error) {
      if (typeof error.data.error === 'string') {
        toast.error(error.data.error);
      } else {
        toast.error(error.data.error[0]);
      }

      console.log('in action register error', error);
    } finally {
      setLoadingAuth(false);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Set isLoggedIn to false
    if (
      pathname !== '/' &&
      !pathname.includes('/resumeandcoverletterbuilder') &&
      !pathname.includes('/resources') &&
      !pathname.includes('/privacypolicy') &&
      !pathname.includes('/termsandconditions')
    )
      history.push('/resumeandcoverletterbuilder/login');
    localStorage.clear();
  };

  const checkTokenExpiration = () => {
    const loginTime = localStorage.getItem('login_time');

    const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
    const currentTime = Date.now();

    if (currentTime - Number(loginTime) >= oneHour) {
      handleLogout();
    } else {
      setTimeout(handleLogout, oneHour - (currentTime - Number(loginTime)));
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleRegister,
        handleLogout,
        isLoggedIn,
        loadingAuth,
        setRouteAfterAuth,
        userDetails,
        setUserDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
