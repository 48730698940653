import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import apiClient from '../../../../api/server';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;

  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Book';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }

  .input-title {
    margin-bottom: 20px;
    font-family: 'Circular Std Bold' !important;
    font-style: normal;
    font-size: 16px !important;
    color: #272833;
  }

  .input-label {
    margin-bottom: 10px;
    font-family: 'Circular Std Book' !important;
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }

  .add-achievement-section {
    width: 100%;
    text-align: right;
  }

  .add-item-section {
    width: 100%;
    text-align: center;
  }

  .form_next_btn {
    min-width: 147px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Circular Std Book' !important;

    :hover {
      background: #5c70cd;
    }
  }

  .checkbox {
    .MuiFormControlLabel-label {
      font-family: 'Circular Std Book' !important;
      font-style: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .input-section {
    margin-bottom: 25px;
  }

  .achievement-section {
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 5%;
    background: rgba(92, 112, 205, 0.01);
  }

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }

  .item-section {
    position: relative;
    border: 1px solid #5c70cd;
    border-radius: 4px;
    padding: 4% 5% 5% 5%;
    margin-bottom: 25px;
    background: rgba(92, 112, 205, 0.05);
  }

  .Mui-error {
    border-radius: 4px;
    border: 1px solid red;
  }

  .error-text {
    color: red;
    font-size: 12px;
    padding: 5px;
  }

  .date-input {
    background-color: #f1f2f5 !important;
    .MuiOutlinedInput-input {
      font-family: Circular Std Book !important;
      width: 100% !important;
      font-size: 16px !important;
      color: rgb(39, 40, 51) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7ed;
    }
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: red !important;
  }

  .remove-text {
    font-size: 14px !important;
    font-family: Circular Std Medium !important;
    margin-top: 3px;
  }

  .skip-button {
    font-family: Circular Std Medium !important;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0em;
    float: right;
    color: rgb(92, 112, 205);
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
  }
`;


const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },

  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    fontFamily: 'Circular Std Book !important',
    '&:focus': {
      borderColor: 'rgba(92, 112, 205, 0.13)',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Circular Std Book !important',
    width: '100%',
    color: 'rgb(39, 40, 51)',
  },
}));


const LicensesCertifications = React.forwardRef(
  ({ back, next, formOnly }, ref) => {
    const formRef = useRef();
    const [data, setData] = useState([
      {
        id: '',
        index: 0,
        nameOfCertificate: '',
        issuingOrganization: '',
        role: 'Education',
        issueDate: moment().toDate(),
        expirationDate: moment().toDate(),
        canExpire: false,
        credentialId: '',
        credentialUrl: '',
      },
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [fromNav, setFromNav] = useState(false);

    useImperativeHandle(ref, () => ({
      async submit() {
        if (formRef.current) {
          setFromNav(true);
          await formRef.current.handleSubmit();
        }
      },
    }));

    const getData = async () => {
      const response = await apiClient.getLicense(localStorage.getItem("userId"));
      if (response.status) {
        response.data.forEach((item, index) => {
          item.index = index;
          Object.keys(item).forEach((key) => {
            if (item[key] == null) {
              item[key] = '';
            }
          });
        });

        if (response.data.length > 0) {
          setData(response.data);
          //  console.log(response.data);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    const validationSchema = yup.array().of(
      yup.object().shape({
        nameOfCertificate: yup
          .string('Enter the certificate')
          .required('Certificate Name is required'),
        issuingOrganization: yup
          .string('Enter the issuing organisation')
          .required('Issuing organisation is required'),
        issueDate: yup
          .string('Enter the Issue Date')
          .required('Issue Date is required'),
      })
    );

    const handleFormSubmit = async (values, setSubmitting) => {
      const response = await apiClient.postLicense(localStorage.getItem("userId"), values);

      if (response.status) {
        if (!formOnly) {
          if (fromNav) {
            next(true);
          } else {
            next();
          }
        } else {
          toast.success('Update Successful');
          setSubmitting(false);
        }
      } else {
        toast.error(response.message);
        setSubmitting(false);
      }
    };

    const handleBack = () => {
      back();
    };

    const handleAddEntry = (setFieldValue) => {
      const array = formRef.current.values;
      array.push({
        id: '',
        index: array.length,
        nameOfCertificate: '',
        issuingOrganization: '',
        role: 'Education',
        issueDate: moment().toDate(),
        expirationDate: moment().toDate(),
        canExpire: false,
        credentialId: '',
        credentialUrl: '',
      });
      setFieldValue(``, array, false);
    };

    const handleDeleteEntry = (index, setFieldValue) => {
      const array = formRef.current.values;
      array.splice(index, 1);
      setFieldValue(``, array, false);
    };

    useEffect(() => {
      setIsLoading(true);
      getData();
    }, []);

    return (
      <MainContent>
        {!isLoading && (
          <Box>
            {!formOnly && (
              <Box>
                <button
                  type="button"
                  className="skip-button"
                  onClick={() => next()}
                >
                  Skip This
                </button>
                <p className="title">Licenses and Certifications</p>
                <p className="description">
                  Do you have any licenses and/or certifications?
                </p>
              </Box>
            )}

            <Container id="form-section" className="form-container">
              <Formik
                innerRef={formRef}
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={data}
                onSubmit={(values, { setSubmitting }) => {
                  handleFormSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {values.map((item, index) => (
                      <Box key={`${item.index}`}>
                        <Box className="item-section">
                          <p className="input-title">
                            License/Certificate #{index + 1}
                          </p>
                          <Box className="input-section">
                            <p className="input-label">Name</p>
                            <IconButton
                              aria-label="delete"
                              component="span"
                              disableFocusRipple
                              className="delete-button"
                              onClick={() =>
                                handleDeleteEntry(index, setFieldValue)
                              }
                            >
                              <DeleteIcon />
                              <span className="remove-text">Remove</span>
                            </IconButton>
                            <FormControl
                              className="form-container"
                              variant="standard"
                            >
                              <BootstrapInput
                                placeholder="E.g. Business Certification, AWS Certification, etc."
                                id="text-input"
                                value={item.nameOfCertificate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={`[${index}].nameOfCertificate`}
                                error={
                                  getIn(
                                    errors,
                                    `[${index}].nameOfCertificate`
                                  ) &&
                                  getIn(touched, `[${index}].nameOfCertificate`)
                                }
                              />
                              {getIn(errors, `[${index}].nameOfCertificate`) &&
                              getIn(touched, `[${index}].nameOfCertificate`) ? (
                                <Box className="error-text">
                                  {getIn(
                                    errors,
                                    `[${index}].nameOfCertificate`
                                  )}
                                </Box>
                              ) : null}
                            </FormControl>
                          </Box>

                          <Box className="input-section">
                            <p className="input-label">Issuing Organisation</p>
                            <FormControl
                              className="form-container"
                              variant="standard"
                            >
                              <BootstrapInput
                                placeholder="E.g. Oracle, Amazon, etc."
                                id="text-input"
                                value={item.issuingOrganization}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={`[${index}].issuingOrganization`}
                                error={
                                  getIn(
                                    errors,
                                    `[${index}].issuingOrganization`
                                  ) &&
                                  getIn(
                                    touched,
                                    `[${index}].issuingOrganization`
                                  )
                                }
                              />
                              {getIn(
                                errors,
                                `[${index}].issuingOrganization`
                              ) &&
                              getIn(
                                touched,
                                `[${index}].issuingOrganization`
                              ) ? (
                                <Box className="error-text">
                                  {getIn(
                                    errors,
                                    `[${index}].issuingOrganization`
                                  )}
                                </Box>
                              ) : null}
                            </FormControl>
                          </Box>

                          <Box className="input-section">
                            <p className="input-label">Issue Started</p>

                            <FormControl
                              className="form-container"
                              variant="standard"
                            >
                              <MobileDatePicker
                                inputFormat="MM/YYYY"
                                views={['year', 'month']}
                                clearable
                                placeholder="Issue Date"
                                value={item.issueDate}
                                onChange={(value) =>
                                  setFieldValue(`[${index}].issueDate`, value)
                                }
                                onBlur={handleBlur}
                                error={
                                  getIn(errors, `[${index}].issueDate`) &&
                                  getIn(touched, `[${index}].issueDate`)
                                }
                                name={`[${index}].issueDate`}
                                renderInput={(params) => (
                                  <TextField
                                    className="date-input"
                                    {...params}
                                  />
                                )}
                              />

                              {getIn(errors, `[${index}].issueDate`) &&
                              getIn(touched, `[${index}].issueDate`) ? (
                                <Box className="error-text">
                                  {getIn(errors, `[${index}].issueDate`)}
                                </Box>
                              ) : null}
                            </FormControl>
                          </Box>
                          <Box className="input-section">
                            <FormControlLabel
                              className="checkbox"
                              control={
                                <Checkbox
                                  checked={item.canExpire}
                                  onChange={handleChange}
                                  sx={{
                                    '&.Mui-checked': {
                                      color: '#5c70cd',
                                    },
                                  }}
                                  name={`[${index}].canExpire`}
                                />
                              }
                              label="This credential does not expire"
                            />
                          </Box>
                          {!item.canExpire && (
                            <Box className="input-section">
                              <p className="input-label">End Date</p>

                              <FormControl
                                className="form-container"
                                variant="standard"
                                error={false}
                              >
                                <MobileDatePicker
                                  inputFormat="MM/YYYY"
                                  views={['year', 'month']}
                                  clearable
                                  placeholder="Expiration Date"
                                  value={item.expirationDate}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `[${index}].expirationDate`,
                                      value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  name={`[${index}].expirationDate`}
                                  error={false}
                                  renderInput={(params) => (
                                    <TextField
                                      error={false}
                                      className="date-input"
                                      {...params}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Box>
                          )}

                          <Box className="input-section">
                            <p className="input-label">Credential ID</p>
                            <FormControl
                              className="form-container"
                              variant="standard"
                            >
                              <BootstrapInput
                                placeholder="Enter an ID associated with the credential"
                                id="text-input"
                                value={item.credentialId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={`[${index}].credentialId`}
                                error={
                                  getIn(errors, `[${index}].credentialId`) &&
                                  getIn(touched, `[${index}].credentialId`)
                                }
                              />
                              {getIn(errors, `[${index}].credentialId`) &&
                              getIn(touched, `[${index}].credentialId`) ? (
                                <Box className="error-text">
                                  {getIn(errors, `[${index}].credentialId`)}
                                </Box>
                              ) : null}
                            </FormControl>
                          </Box>

                          <Box className="input-section">
                            <p className="input-label">Credential URL</p>
                            <FormControl
                              className="form-container"
                              variant="standard"
                            >
                              <BootstrapInput
                                placeholder="Enter the URL associated with the credential"
                                id="text-input"
                                value={item.credentialUrl}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name={`[${index}].credentialUrl`}
                                error={
                                  getIn(errors, `[${index}].credentialUrl`) &&
                                  getIn(touched, `[${index}].credentialUrl`)
                                }
                              />
                              {getIn(errors, `[${index}].credentialUrl`) &&
                              getIn(touched, `[${index}].credentialUrl`) ? (
                                <Box className="error-text">
                                  {getIn(errors, `[${index}].credentialUrl`)}
                                </Box>
                              ) : null}
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    ))}

                    <Box className="add-item-section">
                      <Button
                        variant="contained"
                        disableFocusRipple
                        disableRipple
                        disableElevation
                        className="form_next_btn"
                        onClick={() => handleAddEntry(setFieldValue)}
                        startIcon={<AddIcon />}
                      >
                        Add License
                      </Button>
                    </Box>

                    <Box className="button-section">
                      {!isSubmitting && (
                        <Box>
                          {!formOnly && (
                            <button
                              type="button"
                              className="back-button inline"
                              onClick={handleBack}
                            >
                              Back
                            </button>
                          )}

                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="form_next_btn inline"
                          >
                            {formOnly ? 'Save' : 'Next Step'}
                          </button>
                        </Box>
                      )}
                      {isSubmitting && <CircularProgress />}
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        )}
        {isLoading && (
          <Container className="loading-container">
            <CircularProgress size={60} />
          </Container>
        )}
      </MainContent>
    );
  }
);
LicensesCertifications.displayName = 'LicensesCertifications';
export default LicensesCertifications;
