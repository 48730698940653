import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/user-context';
import Footer from '../Footer';
import Navbar from '../Navbar';

export const Layout = ({ children }) => {
  const { pathname } = useLocation();

  const { preferenceStep, planPaymentResponse } = useUser();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, preferenceStep, planPaymentResponse]);

  return (
    <>
     
      <Navbar />
      <main
        className={`overflow-hidden  ${
          !pathname.includes('resumeandcoverletterbuilder') &&
          !pathname.includes('forgotpassword') &&
          'pt-[120px] pb-[80px]'
        } `}
      >
        {children}
      </main>
      {!pathname.includes('resumeandcoverletterbuilder') && <Footer />}
    </>
  );
};
