import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import useSWR from 'swr';
import produce from 'immer';
import apiClient from '../../../../../api/server';
import FormWrapper from '../../FormWrapper';
import { postHeadline } from '../../../../../redux/actions';

export default function HeadlineForm({ formShown, noFooter, formName }) {
  /* Get current user */
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [initialValues, setinitialValues] = useState({
    details: '',
  });

  // /* Get user headline from backend */
  // const { data } = useSWR(
  //   () => `/users/${user.id}/headline`,
  //   () => apiClient.getHeadline(localStorage.getItem("userId"))
  // );

  // /* Set the Intial Headline value */
  // useEffect(() => {
  //   if (data?.data) {
  //     const { data: userHeadlineDetails } = data;
  //     setinitialValues((i) =>
  //       produce(i, (draft) => {
  //         draft.details = userHeadlineDetails.details;
  //       })
  //     );
  //   }
  // }, [data]);

  /**
   * Counts Headline words on Change of input value
   *
   * @function
   * @param {Sting} payload The current text bein typed in
   * @return {void}
   */

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // onSubmit={(values) => {
      //   if (data.data?.id)
      //     dispatch(postHeadline(localStorage.getItem("userId"), values, 'put', data.data.id));
      //   else dispatch(postHeadline(localStorage.getItem("userId"), values));
      // }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
      }) => (
        <FormWrapper
          formShown={formShown}
          noFooter={noFooter}
          formName={formName}
          handleSubmit={handleSubmit}
        >
          <label>
            <span className="">Headline</span>
            <br />
            <textarea
              name="details"
              placeholder="Let’s hear a compelling summary of your experiences, achievements
                and background. This is the first thing recruiters and hiring
                managers look at when you present your resume."
              value={values.details}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              className={errors.details && touched.details && 'rez_input_error'}
            />
            {errors.details}
            {touched.details}
            {/* {values?.details?.length < 150 && (
              <span className="textarea_wordcount">
                {150 - values?.details?.length} words left
              </span>
            )} */}
          </label>
        </FormWrapper>
      )}
    </Formik>
  );
}
