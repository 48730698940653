import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCountries, getCountry } from '@loophq/country-state-list';
import useSWR from 'swr';
import { Formik } from 'formik';
import produce from 'immer';
import apiClient from '../../../../../api/server';
import FormWrapper from '../../FormWrapper';
import { postContact } from '../../../../../redux/actions';

const ContactForm = ({ noFooter, formShown, formName }) => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { data } = useSWR(
    () => `/users/${localStorage.getItem("userId")}/contact`,
    () => apiClient.getContact(localStorage.getItem("userId"))
  );

  const [initialValues, setinitialValues] = useState({
    firstName: '',
    lastName: '',
    country: '',
    city: '',
    linkedInUrl: '',
    portfolioUrl: '',
  });

  const [country, setCountry] = useState([]);
  const [CTstate, setCTstate] = useState([]);
  const [newData, setNewData] = useState({});
  const [customCountry, setCustomCountry] = useState('');

  useEffect(() => {
    setCountry(getCountries());
    setTimeout(() => {
      if (data?.data) {
        const { data: userContactDetails } = data;
        setinitialValues((i) =>
          produce(i, (draft) => {
            draft.country = userContactDetails.country;
            draft.city = userContactDetails.city;
            draft.linkedInUrl = userContactDetails.linkedInUrl;
            draft.portfolioUrl = userContactDetails.portfolioUrl;
            draft.firstName = userContactDetails.user.firstName;
            draft.lastName = userContactDetails.user.lastName;
          })
        );
        if (userContactDetails?.country) {
          const userCountry = country.find(
            (x) => x.name === userContactDetails.country
          );
          setNewData({ ...newData, country: userContactDetails.country });
          setCustomCountry(`${userContactDetails.country},${userCountry.code}`);
          setCTstate(userCountry.states);
        }
      }
    }, 1000);
  }, [data]);

  const handleGetState = (e) => {
    const countryObj = getCountry(e);
    setCTstate(countryObj.states);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        if (data.data?.id)
          dispatch(
            postContact(localStorage.getItem("userId"), { ...values, ...newData }, 'put', data.data.id)
          );
        else dispatch(postContact(localStorage.getItem("userId"), { ...values, ...newData }));
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <FormWrapper
          formShown={formShown}
          noFooter={noFooter}
          formName={formName}
          handleSubmit={handleSubmit}
        >
          <label>
            <span className="">First Name</span>
            <br />
            <input
              name="firstName"
              type="text"
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
            />
          </label>

          <label>
            <span className="">Last Name</span>
            <br />
            <input
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
            />
          </label>

          <label>
            <span className="important-field">Country</span>
            <br />
            <select
              name="country"
              value={customCountry}
              onChange={(e) => {
                const [countryName, countryCode] = e.target.value.split(',');
                setinitialValues({ ...initialValues, country: countryName });
                setNewData({ ...newData, country: countryName });
                setCustomCountry(e.target.value);
                handleGetState(countryCode);
              }}
              className={errors.country && touched.country && 'rez_input_error'}
              required
            >
              <option value="">Select country</option>
              {country.map((st) => (
                <option key={st.code} value={`${st.name},${st.code}`}>
                  {st.name}
                </option>
              ))}
            </select>
          </label>

          <label>
            <span className="important-field">State/City</span>
            <br />

            <select
              name="city"
              value={values.city}
              onChange={handleChange}
              className={errors.city && touched.city && 'rez_input_error'}
              required
            >
              <option value="">Select State/City</option>
              {CTstate.map((st) => (
                <option key={st} value={st}>
                  {st}
                </option>
              ))}
            </select>
          </label>

          <label>
            <span className="">LinkedIn URL</span>
            <br />
            <input
              name="linkedInUrl"
              type="text"
              placeholder="https://"
              value={values.linkedInUrl}
              onChange={handleChange}
            />
          </label>

          <label>
            <span className="">Portfolio URL</span>
            <br />
            <input
              name="portfolioUrl"
              type="text"
              placeholder="https://"
              value={values.portfolioUrl}
              onChange={handleChange}
            />
          </label>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default ContactForm;
