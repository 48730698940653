import React from 'react';
import { Link } from 'react-router-dom';

export const ProductBox = ({ icon, text, link, bottom }) => (
  <div>
    <Link
      to={link}
      className="bg-[#E7F8FF] border border-[#0000001A] hover:border-[#5c70cd] p-6 h-[281px] pt-9 flex flex-col justify-center items-center"
    >
      <img className="max-w-[160px] mb-3" src={icon} alt="" />
      <p className="text-xl text-center">{text}</p>
    </Link>
    {bottom && bottom}
  </div>
);
