import axios from 'axios';
import { toast } from 'react-toastify';
import { envConfig } from '../../utils/envConfig';

export const request = async (method, url, requestData, params) => {
  try {
    const { data } = await axios({
      baseURL: envConfig.serverBaseUrl,
      url,
      method,
      params,
      data: requestData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('rezzie_token')}`,
      },
    });
    return Promise.resolve(data);
  } catch (err) {
    if (err.response.status === 401 && err.response.data === 'Token Expired') {
      const result = await request('post', '/auth/refreshtoken', null);
      if (result.code === 200) {
        localStorage.setItem('rezzie_token', result.token);
        const { data } = await axios({
          baseURL: envConfig.serverBaseUrl,
          url,
          method,
          params,
          data: requestData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('rezzie_token')}`,
          },
        });
        return Promise.resolve(data);
      }
      localStorage.clear();
    }

    if (method !== 'get') {
      if (err.response.data.message === 'Validation Error!') {
        toast.error(Object.values(err.response.data.data).join('  '));
        const error = {
          status: false,
          data: Object.values(err.response.data.data).join('  '),
        };
        return Promise.reject(error);
      }
    }

    const error = {
      status: err.response?.status,
      data: err.response?.data,
    };
    return Promise.reject(error);
  }
};

export default {
  /* Auth */
  register(data) {
    return request('post', '/auth/register_user', data);
  },

  login(data) {
    return request('post', '/auth/login', data);
  },

  linkedinLogin(data) {
    return request('post', '/auth/linkedin/login', data);
  },

  googleLogin(data) {
    return request('post', '/auth/google/login', data);
  },

  forgotPassword(data) {
    return request('post', '/auth/forgot_password', data);
  },

  getContact(id) {
    return request('get', `/users/${id}/contact`);
  },

  postContact(id, data) {
    return request('post', `/users/${id}/contact`, data);
  },

  editContact(id, data, exID) {
    return request('patch', `/users/${id}/contact/${exID}/edit`, data);
  },

  getHeadline(id) {
    return request('get', `/users/${id}/headline`);
  },

  editHeadline(id, data, exID) {
    return request('patch', `/users/${id}/headline/${exID}/edit`, data);
  },

  postHeadline(id, data) {
    return request('post', `/users/${id}/headline`, data);
  },

  getEducation(userId) {
    return request('get', `/users/${userId}/education`);
  },

  postEducation(userId, data) {
    return request('post', `/users/${userId}/education`, data);
  },

  editEducation(userId, data, id) {
    return request('patch', `/users/${userId}/education/${id}/edit`, data);
  },
  deleteEducation(userId, id) {
    return request('delete', `/users/${userId}/education/${id}`);
  },

  getPublication(userId) {
    return request('get', `publication/${userId}`);
  },
  getAPublication(userId, id) {
    return request('get', `publication/${id}/users/${userId}`);
  },
  postPublication(userId, data) {
    return request('post', `/publication/${userId}`, data);
  },

  editPublication(userId, id, data) {
    return request('patch', `publication/${id}/users/${userId}/edit`, data);
  },
  deletePublication(userId, id) {
    return request('delete', `publication/${id}/users/${userId}`);
  },
  getCourse(userId) {
    return request('get', `course/${userId}`);
  },
  getACourse(userId, id) {
    return request('get', `course/${id}/users/${userId}`);
  },
  postCourse(userId, data) {
    return request('post', `/course/${userId}`, data);
  },

  editCourse(userId, id, data) {
    return request('patch', `course/${id}/users/${userId}/edit`, data);
  },
  deleteCourse(userId, id) {
    return request('delete', `course/${id}/users/${userId}`);
  },
  getLanguage(userId) {
    return request('get', `language/${userId}`);
  },
  getALanguage(userId, id) {
    return request('get', `language/${id}/users/${userId}`);
  },
  postLanguage(userId, data) {
    return request('post', `/language/${userId}`, data);
  },

  editLanguage(userId, id, data) {
    return request('patch', `language/${id}/users/${userId}/edit`, data);
  },
  deleteLanguage(userId, id) {
    return request('delete', `language/${id}/users/${userId}`);
  },
  getOrganization(userId) {
    return request('get', `organisation/${userId}`);
  },
  getAOrganization(userId, id) {
    return request('get', `organisation/${id}/users/${userId}`);
  },
  postOrganization(userId, data) {
    return request('post', `/organisation/${userId}`, data);
  },

  editOrganization(userId, id, data) {
    return request('patch', `organisation/${id}/users/${userId}/edit`, data);
  },
  deleteOrganization(userId, id) {
    return request('delete', `organisation/${id}/users/${userId}`);
  },
  getProject(userId) {
    return request('get', `project/${userId}`);
  },
  getAProject(userId, id) {
    return request('get', `project/${id}/users/${userId}`);
  },
  postProject(userId, data) {
    return request('post', `/project/${userId}`, data);
  },

  editProject(userId, id, data) {
    return request('patch', `project/${id}/users/${userId}/edit`, data);
  },
  deleteProject(userId, id) {
    return request('delete', `project/${id}/users/${userId}`);
  },

  getPatent(userId) {
    return request('get', `patent/${userId}`);
  },
  getAPatent(userId, id) {
    return request('get', `patent/${id}/users/${userId}`);
  },
  postPatent(userId, data) {
    return request('post', `/patent/${userId}`, data);
  },

  editPatent(userId, id, data) {
    return request('patch', `patent/${id}/users/${userId}/edit`, data);
  },
  deletePatent(userId, id) {
    return request('delete', `patent/${id}/users/${userId}`);
  },
  getHonor(userId) {
    return request('get', `honor/${userId}`);
  },
  getAHonor(userId, id) {
    return request('get', `honor/${id}/users/${userId}`);
  },
  postHonor(userId, data) {
    return request('post', `/honor/${userId}`, data);
  },

  editHonor(userId, id, data) {
    return request('patch', `honor/${id}/users/${userId}/edit`, data);
  },
  deleteHonor(userId, id) {
    return request('delete', `honor/${id}/users/${userId}`);
  },
  getTestScore(userId) {
    return request('get', `testscore/${userId}`);
  },
  getATestScore(userId, id) {
    return request('get', `testscore/${id}/users/${userId}`);
  },
  postTestScore(userId, data) {
    return request('post', `/testscore/${userId}`, data);
  },

  editTestScore(userId, id, data) {
    return request('patch', `testscore/${id}/users/${userId}/edit`, data);
  },
  deleteTestScore(userId, id) {
    return request('delete', `testscore/${id}/users/${userId}`);
  },

  getExperience(userId) {
    return request('get', `/users/${userId}/workexperience`);
  },

  postExperience(userId, data) {
    return request('post', `/users/${userId}/workexperience`, data);
  },

  editExperience(userId, data, id) {
    return request('patch', `/users/${userId}/workexperience/${id}/edit`, data);
  },
  deleteExperience(userId, id) {
    return request('delete', `/users/${userId}/workexperience/${id}`);
  },

  getLicense(userId) {
    return request('get', `/users/${userId}/licenseAndCertificate`);
  },

  postLicense(userId, data) {
    return request('post', `/users/${userId}/licenseAndCertificate`, data);
  },

  editLicense(userId, data, id) {
    return request(
      'patch',
      `/users/${userId}/licenseAndCertificate/${id}/edit`,
      data
    );
  },
  deleteLicense(userId, id) {
    return request('delete', `/users/${userId}/licenseAndCertificate/${id}`);
  },

  getVolunteer(userId) {
    return request('get', `/users/${userId}/volunteerHistory`);
  },

  editVolunteer(userId, data, id) {
    return request(
      'patch',
      `/users/${userId}/volunteerHistory/${id}/edit`,
      data
    );
  },
  deleteVolunteer(userId, id) {
    return request('delete', `/users/${userId}/volunteerHistory/${id}`);
  },

  postVolunteer(userId, data) {
    return request('post', `/users/${userId}/volunteerHistory`, data);
  },

  getSkills(id) {
    return request('get', `/users/${id}/skills`);
  },

  postSkills(id, data) {
    return request('post', `/users/${id}/skills`, data);
  },

  editSkills(id, data) {
    return request('patch', `/users/${id}/skills/edit`, data);
  },

  getUserProfile(id) {
    return request('get', `/auth/user/${id}`);
  },

  getTemplatePreview(userId, templateId) {
    return request('get', `/template/preview/${userId}/${templateId}`);
  },

  getTemplatePdf(userId, templateId) {
    return request('get', `/template/pdf/${userId}/${templateId}`);
  },

  setTemplate(userId, templateId) {
    return request('post', `/template/settemplate/${userId}/${templateId}`);
  },

  getCoverLetter(userId) {
    return request('get', `/coverletter/${userId}`);
  },

  getCoverLetterPreview(userId) {
    return request('get', `/coverletter/preview/${userId}`);
  },

  getCoverLetterPdf(userId) {
    return request('get', `/coverletter/pdf/${userId}`);
  },

  createCoverLetter(userId, data) {
    return request('post', `/coverletter/${userId}`, data);
  },

  selectCoverLetterTemplate(userId, templateId) {
    return request('post', `/coverletter/${userId}/${templateId}`);
  },
  uploadResumeToBuilder(data) {
    return request('post', '/resume/parse', data);
  },
};
