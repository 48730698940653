import React from 'react';
import { ResumeReviewUpload } from '../../../components/ResumeReviewUpload';
import { useUser } from '../../../contexts/user-context';

export const UploadScreen = () => {
  const {
    startReview,
    clearResume,
    userResume,
    reviewResults,
  } = useUser();

  return (
    <div className="">
      <div className="text-center text-[20px]  mx-auto mb-5  md:hidden">
        <p>Review your resume</p>
        <p>
          AI will review your resume and give you feedback on your resume&apos;s
          strengths and weaknesses
        </p>
      </div>
      <ResumeReviewUpload />

      {reviewResults ? (
        <button
          type="button"
          className="border border-2 border-[#19BCFE] text-[#19BCFE] py-2 px-10 text-[22px] rounded-lg mt-8 block mx-auto md:mx-0 disabled:bg-gray-300 disabled:text-white"
          onClick={clearResume}
        >
          Back
        </button>
      ) : (
        <button
          type="button"
          className="bg-[#19BCFE] text-white py-2 px-10 text-[22px] rounded-lg mt-8 block mx-auto md:mx-0 disabled:bg-gray-300 disabled:text-white"
          onClick={startReview}
          disabled={!userResume}
        >
          Start review
        </button>
      )}
    </div>
  );
};
