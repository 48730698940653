import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { CoverDiv, MoreDescription } from '../auth/styles';


export const AuthLayout = () => (
  <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={12} md={6}>
    <CoverDiv>
      <p className="cover-caption">
        Resume Builder, Cover Letter builder and so much more
      </p>
      <p className="cover-description">
        Join us! We&apos;re building a safe space for job seekers and a
        community of ethical and conscientious employers.
        <br />
        <br />
        Find help from our toolkit to make your job applications easier, faster
        and outstanding. From inspiring cover letters to catchy resumes, over
        1000 professionals benefit from our templates and you could too!
      </p>
      <MoreDescription>
        <span>
          💼&nbsp;&nbsp;&nbsp;You&apos;ll find here a community of like-minded
          professionals
        </span>

        <br />
        <br />
        <span>
          🚀&nbsp;&nbsp;&nbsp;Our tools have been approved by recruiters across
          various industries <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; including yours.
        </span>
        <br />
        <br />
        <span>
          😉&nbsp;&nbsp;&nbsp;Empower your recruitment team with our educational
          resources
        </span>
        <br />
        <br />
        <span>
          💃🏼&nbsp;&nbsp;&nbsp;All for <strong>FREE</strong>!
        </span>
      </MoreDescription>
    </CoverDiv>
  </Grid>
);
