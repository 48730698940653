import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import Routes from './routes';
import { Layout } from './components/Layouts/Layout';
import { ProtectedRoute } from './components/ProtectedRoute';

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: 'QuickSand',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Switch>
          {Routes.map(({ component, exact, path, protectedRoute }) =>
            protectedRoute ? (
              <ProtectedRoute
                key={component}
                component={component}
                exact={exact}
                path={path}
              />
            ) : (
              <Route
                key={component}
                component={component}
                exact={exact}
                path={path}
              />
            )
          )}
        </Switch>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
