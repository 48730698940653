import React, { Suspense, lazy, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { Stack } from '@mui/material';

import apiClient from '../../api/server';
import Wrapper from './layout/Wrapper';
import Heading from '../onboarding/components/Heading&Caption';
// import stars from '../../assets/images/selecttemplate/christmas-stars 1.png';
// import briefcase from '../../assets/images/selecttemplate/breifcase.png';
// import cohesion from '../../assets/images/selecttemplate/cohesion 1.png';
// import laptop from '../../assets/images/selecttemplate/laptop 1.png';
// import science from '../../assets/images/selecttemplate/science 1.png';
// import smiley from '../../assets/images/selecttemplate/smile-face 1.png';
import Loader from '../../components/TemplateLoader';
import { SET_ACTIVE_TEMPLATE } from '../../redux/actionTypes';
import TemplateViewer from './templateViewer';
import {
  PreviewItem,
  PreviewItemWrapper,
  TemplatePreviewRow,
  FooterBtns,
  //  Category,
} from './styles';

/* All resume Components are Lazy loaded */
const Resume1 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume1')), 3000);
    })
);

const Resume2 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume2')), 3000);
    })
);

const Resume3 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume3')), 3000);
    })
);

const Resume4 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume4')), 3000);
    })
);

const Resume5 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume5')), 3000);
    })
);

const Resume6 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume6')), 3000);
    })
);

const Resume7 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume7')), 3000);
    })
);

const Resume8 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume8')), 3000);
    })
);

const Resume9 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume9')), 3000);
    })
);

const Resume10 = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./components/Resume10')), 3000);
    })
);

/* Custom styles for the modal */
// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '100%',
//     maxWidth: '705px',
//     height: '90vh',
//     zIndex: '1',
//   },
//   overlay: { backgroundColor: '#000000a6' },
// };

const SelectTemplate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { activeTemplate } = useSelector((state) => state.template);
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  const [toPreview, setToPreview] = useState(0);
  // const [allCategories] = useState([
  //   {
  //     icon: stars,
  //     title: 'Popular',
  //   },
  //   {
  //     icon: smiley,
  //     title: 'Most Used',
  //   },
  //   {
  //     icon: cohesion,
  //     title: 'For All',
  //   },
  //   {
  //     icon: laptop,
  //     title: 'Technology',
  //   },
  //   {
  //     icon: briefcase,
  //     title: 'Business',
  //   },
  //   {
  //     icon: science,
  //     title: 'Science',
  //   },
  // ]);

  const getProfile = async () => {
    const res = await apiClient.getUserProfile(localStorage.getItem("userId"));
    if (res.status) {
      setUserData(res.data);
    }
  };

  /* All resumes components array */
  const allRezumes = [
    <Resume1 key="rezume1" />,
    <Resume2 key="rezume2" />,
    <Resume3 key="rezume3" />,
    <Resume4 key="rezume4" />,
    <Resume5 key="rezume5" />,
    <Resume6 key="rezume6" />,
    <Resume7 key="rezume7" />,
    <Resume8 key="rezume8" />,
    <Resume9 key="rezume9" />,
    <Resume10 key="rezume10" />,
  ];

  const chooseTemplate = async (templateId) => {
    dispatch({
      type: SET_ACTIVE_TEMPLATE,
      payload: templateId,
    });

    const res = await apiClient.setTemplate(localStorage.getItem("userId"), templateId);

    if (res.status) {
      history.push(`/resumesample/${templateId}`);
    }
  };

  const [open, setOpen] = useState(false);

  const openModal = (templateId) => {
    setToPreview(templateId);
    setOpen(true);
  };

  const closeModal = () => setOpen(false);
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <Wrapper>
      <Heading
        caption={t('The exciting part')}
        smallCaption={t('What sort of feel')}
        centerHeading
      />
      {open && (
        <TemplateViewer
          open={open}
          close={closeModal}
          user={userData}
          templateId={toPreview}
        />
      )}

      {/* <Stack
        sx={{ display: { xs: 'none', sm: 'flex' } }}
        direction="row"
        spacing={2}
      >
        {allCategories.map((item) => (
          <Category icon={item.icon} key={`${Date.now()}_${item.title}_`}>
            <div className="category__icon" />
            <div className="category__title">
              <span>{item.title}</span>
            </div>
          </Category>
        ))}
      </Stack> */}

      {/* <Stack
        sx={{
          display: { xs: 'flex', sm: 'none' },
          textAlign: 'center',
        }}
        direction="row"
        spacing={2}
      >
        {allCategories.slice(0, 3).map((item) => (
          <Category icon={item.icon} key={`${Date.now()}_${item.title}_`}>
            <div className="category__icon" />
            <div className="category__title">
              <span>{item.title}</span>
            </div>
          </Category>
        ))}
      </Stack>

      <Stack
        sx={{
          display: { xs: 'flex', sm: 'none' },
          textAlign: 'center',
        }}
        direction="row"
        spacing={2}
      >
        {allCategories.slice(3, 6).map((item) => (
          <Category icon={item.icon} key={`${Date.now()}_${item.title}_`}>
            <div className="category__icon" />
            <div className="category__title">
              <span>{item.title}</span>
            </div>
          </Category>
        ))}
      </Stack> */}

      <TemplatePreviewRow>
        {allRezumes.map((item, index) => (
          <PreviewItemWrapper
            key={Date.now() + Math.random() * 10}
            isActive={activeTemplate === index + 1}
          >
            <PreviewItem>
              <Suspense fallback={<Loader />}>{item}</Suspense>
            </PreviewItem>
            <FooterBtns>
              <div
                role="button"
                className='flex items-center'
                onClick={() => {
                  openModal(index + 1);
                }}
                tabIndex={-1 - index}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    openModal(index + 1);
                  }
                }}
              >
                <Eye />
                {t('Preview')}
              </div>

              <div
                role="button"
                tabIndex={-6 - index}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    chooseTemplate(index + 1);
                  }
                }}
                onClick={() => {
                  chooseTemplate(index + 1);
                }}
              >
                {t('Choose Template')}
              </div>
            </FooterBtns>
          </PreviewItemWrapper>
        ))}
      </TemplatePreviewRow>
    </Wrapper>
  );
};

export default SelectTemplate;

const Eye = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
      fill="#5C70CD"
    />
  </svg>
);
