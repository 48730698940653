import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, IconButton, Stack, Divider, Typography } from '@mui/material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ContactInformation from './forms/ContactInformation';
import Headline from './forms/Headline';
import Experience from './forms/Experience';
import Education from './forms/Education';
import VolunteerHistory from './forms/VolunteerHistory';
import LicensesCertifications from './forms/LicensesCertifications';
import Accomplishments from './forms/Accomplishments';
import Skills from './forms/Skills';

const MainContent = styled.main`
  width: 100%;
  max-width: 775px;
  margin: 0 auto;
  padding: 0 16px;
  .title {
    font-family: 'Circular Std Bold';
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 10px;
  }

  .description {
    font-family: 'Circular Std Bold';
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #131936;
    margin-bottom: 30px;
  }

  .form-container {
    padding: 0px;
    width: 100%;
  }
  .input-label {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #272833;
  }

  .tip-section {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
  }

  .tip-img-section {
    flex: 1;
  }

  .tip-content-section {
    flex: 14;
  }

  .tip-icon {
    width: 34px;
  }

  .tip-title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tip-description {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  .words-left {
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #838383;
  }

  .button-section {
    margin-top: 40px;
    text-align: right;
  }
  .inline {
    margin-right: 45px;
    display: inline;

    :last-child {
      margin-right: 0px;
    }
  }
  .form_next_btn {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
  }

  .back-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;

const ResumeBuilder = () => {
  const { t, i18n } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { form } = useParams();

  let buildSteps = [];
  const buildStepsRefs = [];

  for (let i = 0; i < 8; i += 1) {
    buildStepsRefs.push(useRef());
  }

  const [currentBuildStep, setCurrentBuildStep] = useState(0);

  const handleNext = (isFromNav) => {
    if (!isFromNav) {
      if (currentBuildStep.step !== 7) {
        setCurrentBuildStep(buildSteps[currentBuildStep.step + 1]);
      } else {
        history.push('/profile');
      }
    }
  };

  const handleBack = () => {
    if (currentBuildStep.step !== 0) {
      setCurrentBuildStep(buildSteps[currentBuildStep.step - 1]);
    } else {
      history.push('/resume');
    }
  };

  buildSteps = [
    {
      step: 0,
      name: 'contact',
      component: (
        <ContactInformation
          ref={buildStepsRefs[0]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <AccountBoxOutlinedIcon />,
      displayName: t('Contact Information'),
      ref: buildStepsRefs[0],
    },
    {
      step: 1,
      name: 'headline',
      component: (
        <Headline
          ref={buildStepsRefs[1]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <ArticleOutlinedIcon />,
      displayName: t('Headline'),
      ref: buildStepsRefs[1],
    },
    {
      step: 2,
      name: 'experience',
      component: (
        <Experience
          ref={buildStepsRefs[2]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <WorkOutlineOutlinedIcon />,
      displayName: t('Work Experience'),
      ref: buildStepsRefs[2],
    },
    {
      step: 3,
      name: 'education',
      component: (
        <Education
          ref={buildStepsRefs[3]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <SchoolOutlinedIcon />,
      displayName: t('Education'),
      ref: buildStepsRefs[3],
    },
    {
      step: 4,
      name: 'volunteer',
      component: (
        <VolunteerHistory
          ref={buildStepsRefs[4]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <VolunteerActivismOutlinedIcon />,
      displayName: t('Volunteer History'),
      ref: buildStepsRefs[4],
    },
    {
      step: 5,
      name: 'licenses',
      component: (
        <LicensesCertifications
          ref={buildStepsRefs[5]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <ApprovalOutlinedIcon />,
      displayName: t('Licenses and Certifications'),
      ref: buildStepsRefs[5],
    },
    {
      step: 6,
      name: 'accomplishments',
      component: (
        <Accomplishments
          ref={buildStepsRefs[6]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <EmojiEventsOutlinedIcon />,
      displayName: t('Accomplishments'),
      ref: buildStepsRefs[6],
    },
    {
      step: 7,
      name: 'skills',
      component: (
        <Skills
          ref={buildStepsRefs[7]}
          user={user}
          back={handleBack}
          next={handleNext}
        />
      ),
      icon: <EngineeringOutlinedIcon />,
      displayName: t('Skills'),
      ref: buildStepsRefs[7],
    },
  ];

  const routeToStep = async (step) => {
    await currentBuildStep.ref.current.submit();
    setCurrentBuildStep(buildSteps[step]);
  };

  useEffect(() => {
    const step = buildSteps.find((x) => x.name === form);
    setCurrentBuildStep(step);
  }, []);

  useEffect(() => {
    history.replace(`${currentBuildStep.name}`);
  }, [currentBuildStep]);

  return (
    <>
      <MainContent>
        <Box>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            sx={{
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #e4e4e4',
              flexWrap: 'nowrap',
              marginBottom: '40px',
              overflowX: 'scroll',
            }}
            className="navigation"
          >
            {buildSteps.map((item, index) => (
              <Box
                onClick={() => routeToStep(item.step)}
                sx={{ flex: '3', textAlign: 'center', cursor: 'pointer' }}
                key={`${item.step}`}
              >
                {item.step === currentBuildStep.step && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      sx={{
                        color: '#FFFFFF',
                        backgroundColor: '#5c70cd',
                      }}
                    >
                      {item.icon}
                    </IconButton>
                    <Box
                      sx={{
                        marginLeft: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Circular Std Medium',
                          fontSize: { xs: '10px', sm: '12px' },
                          letterSpacing: '0em',
                          marginBottom: '5px',
                          color: '#5C70CD',
                          textAlign: 'left',
                        }}
                      >
                        {t('Step')} {index + 1}
                        /8
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Circular Std Medium',
                          fontSize: { xs: '10px', sm: '12px' },
                          letterSpacing: '0em',
                          marginBottom: '5px',
                          color: '#131936',
                          textAlign: 'left',
                        }}
                      >
                        {item.displayName}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {item.step !== currentBuildStep.step && (
                  <Box sx={{ cursor: 'pointer' }}>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      sx={{
                        paddingLeft: {
                          xs: '3px !important',
                          sm: '8px !important',
                        },
                        color: '#c0c7e4',
                      }}
                    >
                      {item.icon}
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        </Box>

        <Box className="content">
          {buildSteps.map(
            (item) =>
              item.step === currentBuildStep.step && (
                <Box key={`${item.step}`}>{item.component}</Box>
              )
          )}
        </Box>
      </MainContent>
    </>
  );
};

export default ResumeBuilder;
