import React from 'react';
import { useUser } from '../../../contexts/user-context';

const ResultTile = ({ title, message }) => (
  <div>
    <p className="text-2xl font-bold text-black mb-1">{title}</p>
    {typeof message === "string" ? <div className="border border-[#969191] px-5 py-4 rounded-lg text-lg bg-white text-[#00000080] shadow-[0_4px_4px_#90888840]" dangerouslySetInnerHTML={{ __html: message ?? "" }} />
      :
      <div className="border border-[#969191] px-5 py-4 rounded-lg text-lg bg-white text-[#00000080] shadow-[0_4px_4px_#90888840]">
        {message?.map((_message) => <p key={_message} className='[:not(last-of-type)]:mb-1'>{_message}</p>)}
      </div>}


  </div>
);

export const ResultScreen = () => {
  const { reviewResults } = useUser();
  return (
    <div id='resume-review-result' className="border border-[#868686] bg-[#F1F2F5] p-5 rounded-lg text-2xl md:min-h-[570px]">
      <p id='test-script' className="border-b border-gray-300 font-medium pb-1 text-gray-700">
        Review Results
      </p>
      <div className={`${!reviewResults && 'hidden'}`}>
        <table className="my-4 w-full">
          <tbody>
            {reviewResults?.overall_resume_score &&
              <tr>
                <td className="py-1 text-gray-500">Resume score</td>
                <td className="py-1 text-right">
                  {reviewResults?.overall_resume_score?.score}/100
                </td>
              </tr>
            }

            {reviewResults?.overall_job_match_score &&
              <tr>
                <td className="py-1 text-gray-500">Job Match</td>
                <td className="py-1 text-right">
                  {reviewResults?.overall_job_match_score?.score}/100
                </td>
              </tr>
            }

            {reviewResults?.ATS_optimization_and_keywords &&
              <tr>
                <td className="py-1 text-gray-500">ATS Optimization</td>
                <td className="py-1 text-right">
                  {reviewResults?.ATS_optimization_and_keywords?.score}/100
                </td>
              </tr>
            }
            {reviewResults?.clarity_and_readability &&
              <tr>
                <td className="py-1 text-gray-500">Clarity</td>
                <td className="py-1 text-right">
                  {reviewResults.clarity_and_readability.score}/100
                </td>
              </tr>}

            <tr>
              <td className="py-1 text-gray-500">Format</td>
              <td className="py-1 text-right">
                {reviewResults?.format_and_presentation?.score ?? reviewResults?.format_and_readability?.score}/100
              </td>
            </tr>
            <tr>
              <td className="py-1 text-gray-500">Content</td>
              <td className="py-1 text-right">
                {reviewResults?.content_and_relevance.score}/100
              </td>
            </tr>
            {reviewResults?.completeness &&
              <tr>
                <td className="py-1 text-gray-500">Completeness</td>
                <td className="py-1 text-right">
                  {reviewResults.completeness.score}/100
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div>
          <div className="border-b border-gray-300 text-2xl font-medium pb-1 px-1 text-gray-700 mt-10">
            Notes
          </div>

          <div className="grid gap-3 mt-4">
            {reviewResults?.overall_job_match_score &&
              <ResultTile
                title="Overall Job Match"
                message={reviewResults?.overall_job_match_score.justification}
              />
            }
            {reviewResults?.ATS_optimization_and_keywords &&
              <ResultTile
                title="ATS Optimization and Keywords"
                message={reviewResults?.ATS_optimization_and_keywords.justification}
              />
            }
            <ResultTile
              title="Format"
              message={reviewResults?.format_and_presentation?.justification ?? reviewResults?.format_and_readability?.justification}
            />
            {reviewResults?.clarity_and_readability &&
              <ResultTile
                title="Clarity"
                message={reviewResults?.clarity_and_readability.justification}
              />}

            <ResultTile
              title="Content"
              message={reviewResults?.content_and_relevance.justification}
            />
            {reviewResults?.key_matching_skills_and_experiences &&
              <ResultTile
                title="Matching Skills and Experiences"
                message={reviewResults.key_matching_skills_and_experiences.justification}
              />}

            {reviewResults?.missing_required_qualifications &&
              <ResultTile
                title="Missing Required Qualifications"
                message={reviewResults.missing_required_qualifications.justification}
              />
            }
            <div className="text-lg">
              <h3 className="font-bold text-2xl mt-6">Final Recommendations</h3>
              <p className="mb-1" dangerouslySetInnerHTML={{ __html: reviewResults?.final_recommendations.Strengths ?? "" }} />

              <p className="mb-1" dangerouslySetInnerHTML={{ __html: reviewResults?.final_recommendations.Improvement_Areas ?? "" }} />

              <p dangerouslySetInnerHTML={{ __html: reviewResults?.final_recommendations.Actionable_Steps ?? "" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
