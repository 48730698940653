import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PiUploadSimpleLight } from 'react-icons/pi';
import buildLogo from '../../../assets/images/home_icon_build.png';
import searchLogo from '../../../assets/images/home_icon_search.png';
import templateLogo from '../../../assets/images/home_icon_templates.png';
import {
  ResumeAndCoverLetterHomeLayout,
  ProductBox,
} from '../../../components';
import { envConfig } from '../../../utils/envConfig';
import { useUser } from '../../../contexts/user-context';

const ResumeBuilderHome = () => {
  const [resume, setResume] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { setUploadedResumeParsedData } = useUser();

  const history = useHistory();

  const resumeUploadRef = useRef();

  const handleResumeUpload = async (e) => {
    if (!e.target.files) {
      return;
    }
    setResume(e.target.files[0]);
    setIsUploading(true);

    const formData = new FormData();
    formData.append('resume', e.target.files[0]);

    try {
      const res = await axios.post(
        `${envConfig.serverBaseUrl}/resume/parse`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('rezzie_token')}`,
          },
        }
      );

      if (res.data.data) {
        setUploadedResumeParsedData(res.data.data);
        setIsUploading(false);
        history.push('/resume/build/contact');
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      setIsUploading(false);
    }
  };

  return (
    <>
      <ResumeAndCoverLetterHomeLayout>
        <ProductBox
          link="/resume/build/contact"
          icon={buildLogo}
          text="Build a new resume"
          bottom={
            <>
              <input
                type="file"
                accept=".pdf,.docx,.doc"
                className="hidden"
                ref={resumeUploadRef}
                onChange={handleResumeUpload}
                key={resume ? 'file-selected' : 'file-not-selected'}
              />
              <button
                disabled={isUploading}
                type="button"
                className="bg-[#E7F8FF] text-[20px] px-5 py-4 w-full flex items-center justify-between border-x border-b border-[#0000001A] hover:border-t hover:border-[#5c70cd]"
                onClick={() => resumeUploadRef?.current.click()}
              >
                {isUploading ? 'Parsing resume...' : 'Upload resume'}
                <PiUploadSimpleLight size={20} />
              </button>
            </>
          }
        />
        <ProductBox
          link="/profile"
          icon={searchLogo}
          text={
            <>
              View/edit your existing <br /> resume
            </>
          }
        />
        <ProductBox
          link="/selecttemplate"
          icon={templateLogo}
          text={
            <>
              Check out our resume <br /> templates
            </>
          }
        />
      </ResumeAndCoverLetterHomeLayout>
    </>
  );
};
export default ResumeBuilderHome;
