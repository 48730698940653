import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { questionnaire } from '../../../../dummy-data/questionnaire';
import Question from './Question';
import Results from './Results';
import Navbar from '../../../../components/Navbar';

function Questionnaire() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answerCount, setAnswerCount] = useState({ A: 0, B: 0, C: 0 });
  const slideRef = Array.from({ length: 10 }, () => useRef(null));

  const scrollToQuestion = (id, to) => {
    slideRef[to === 'next' ? id + 1 : id - 1].current?.scrollIntoView({
      behavior: 'smooth',
    });
    setCurrentQuestion(to === 'next' ? id + 1 : id - 1);
  };


  return (
    <>
    <Navbar />
    <Box
      as="section"
      position="relative"
      sx={{
        height: '100vh',
        width: '100vw',
        overflowY: 'auto',
        overscrollBehaviorY: 'contain',
        scrollSnapType: 'y mandatory',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {questionnaire.map((q) => (
        <Question
          key={q.id}
          id={`question-${q.id}`}
          ref={slideRef[q.id]}
          {...q}
          setAnswerCount={setAnswerCount}
          scrollToQuestion={scrollToQuestion}
        />
      ))}
      <Results ref={slideRef[9]} answerCount={answerCount} />
      {/* <Box
        display="flex"
        justifyContent="flex-end"
        position="sticky"
        mr={['1.5rem']}
        bottom={['1.5rem']}
        height="fit-content"
      >
        <Button
          sx={{
            background: '#19BCFE',
            color: 'white',
            borderRadius: '8px',
            width: '36px',
            '&:hover': {
              background: '#1ACDFF',
            },
          }}
          disabled={currentQuestion <= 0}
          onClick={() => scrollToQuestion(currentQuestion, 'previous')}
        >
          <ExpandLessIcon />
        </Button>
        <Button
          sx={{
            background: '#19BCFE',
            color: 'white',
            borderRadius: '8px',
            width: '36px',
            marginLeft: '1rem',
            '&:hover': {
              background: '#1ACDFF',
            },
          }}
          onClick={() => scrollToQuestion(currentQuestion, 'next')}
        >
          <ExpandMoreIcon />
        </Button>
      </Box> */}
    </Box>
    </>
  );
}

export default Questionnaire;
