import React from 'react';
import { MagnifyingGlassImg } from '../../assets';
import { useUser } from '../../contexts/user-context';
import { ResultScreen, UploadScreen, LoadingScreen } from './screens';
import Container from '../../components/Container';

export const ResumeReview = () => {
  const { loadingResults, reviewResults } = useUser();

  return (
    <div className="px-6">
      <Container>
        <div className="relative max-w-[430px] mx-auto min-h-[70svh] md:hidden">
          {!loadingResults && !reviewResults && <UploadScreen />}
          {loadingResults && <LoadingScreen />}
          {!loadingResults && reviewResults && <ResultScreen />}

          <img
            className={`scale-x-[-1] relative -left-10 z-[-1] mt-10 md-hidden ${
              reviewResults && 'hidden'
            }`}
            src={MagnifyingGlassImg}
            alt="maginifying glass"
            width={200}
            height={200}
          />
        </div>
        <div className="hidden md:grid grid-cols-[40%_auto] gap-8 md:block pt-12 relative  ">
          <UploadScreen />
          {loadingResults && <LoadingScreen />}
          <ResultScreen />
        </div>
      </Container>
    </div>
  );
};
