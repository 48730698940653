import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import Container from '../Container';

function Footer() {
  return (
    <footer>
      <Container className="grid md:grid-cols-[50%_50%]">
        <img
          className="logo block ml-6 mb-12 md:pt-20 w-[80px]"
          src={logo}
          alt="rezzie logo"
        />

        <Box>
          <Box
            sx={{
              mb: 4,
              display: 'flex',
              justifyContent: { xs: 'space-around', md: 'unset' },
              pl: { xs: 0, md: 3 },
              gap: 6,
              '& .footer-links': {
                display: 'block',
                color: '#707070',
                mb: 1.5,
              },
              '& h5': {
                fontWeight: 600,
                mb: 1.5,
              },
            }}
          >
            <Box>
              <Typography as="h5"> Helpful Links</Typography>
              <Link className="footer-links" to="/">
                Home
              </Link>
              <a
                className="footer-links"
                href="https://rezzieco.wordpress.com"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </Box>
            <Box>
              <Typography as="h5"> Social</Typography>
              <a
                className="footer-links"
                href="https://www.linkedin.com/company/rezzie/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
              <a
                className="footer-links"
                href="https://twitter.com/rezzieco"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
              <a
                className="footer-links"
                href="https://instagram.com/the.rezzie?igshid=ZDg1NjBiNjg="
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </Box>
          </Box>{' '}
     
        </Box>
      </Container>
      <div className="md:w-1/2 ml-auto text-xs border-t border-[#c1c1c1] h-12 flex items-center justify-center md:justify-start gap-4">
        <span>@ 2021 Rezzie. All rights reserved.</span>
        <div className="flex gap-1">
          <Link className="block" to="/privacypolicy" target="_blank">
            Privacy Policy
          </Link>
          <span>|</span>
          <Link className="block" to="/termsandconditions" target="_blank">
            Terms of Use
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
