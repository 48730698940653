import React from 'react';
import { LoaderGif, MagnifyingGlassImg } from '../../../assets';

export const LoadingScreen = () => (
  <div className="text-center text-2xl max-w-[360px] mx-auto mb-5 md:m-0 md:fixed md:max-w-[unset] md:w-screen md:h-screen md:z-10 md:bg-[#F4F4F4E0] md:backdrop-blur-sm md:pt-40 md:top-0 md:left-0">
    <img
      className="scale-[-1] absolute top-[100px] -left-10 z-[-1] hidden md:block"
      src={MagnifyingGlassImg}
      alt="maginifying glass"
      width={221}
      height={221}
    />
    <p className="mb-6">This will only take a moment </p>
    <p>Resume review in progress</p>
    <img
      className="block mt-[140px] mx-auto max-w-[360px]"
      src={LoaderGif}
      alt="loading results"
    />
    <img
      className=" absolute -bottom-[10px] right-1 z-[-1] hidden md:block"
      src={MagnifyingGlassImg}
      alt="maginifying glass"
      width={221}
      height={221}
    />
  </div>
);
