import React from 'react';
import { Link } from 'react-router-dom';
import { RobotWithNote, ThreeRobotsImg, TwoRobotsImg } from '../../assets';
import { useUser } from '../../contexts/user-context';

export const CareerCoachHome = () => {
  const { planPaymentResponse } = useUser();

  return (
    <section className="px-6 max-w-[430px] mx-auto text-center text-2xl pb-16">
      {!planPaymentResponse.success ? (
        <>
          <p className="text-center text-3xl max-w-[360px] mx-auto mb-8 max-w-[230px] font-semibold">
            Hi!!!! Welcome to
            <br />
            <span className="text-[#FF7160]">Career Coach</span>
          </p>

          <p className="text-xl text-center">
            This AI tool is to help you by offering you personalized career
            advice and resume optimization.
          </p>
          <img
            className="mt-9 mb-20"
            src={ThreeRobotsImg}
            alt="robots"
            loading="lazy"
          />
          <Link
            to="/careercoach/preferences"
            className="w-fit bg-[#19BCFE] px-10 py-1 text-[22px] rounded-lg text-[#FFFFFFC9] mt-8 block mx-auto"
          >
            Get Started
          </Link>
        </>
      ) : (
        <>
          <img
            className="mb-2"
            src={TwoRobotsImg}
            alt="two robots"
            loading="lazy"
          />
          <p className="font-semibold mb-2">AI Career Coach</p>
          <p className="text-center">
            Let&apos;s get down to helping you in progressing down your career
            path by speaking with your career coach
          </p>
          <Link
            to="/careercoach"
            className="w-fit bg-[#19BCFE] px-10 py-1 text-[22px] rounded-lg text-[#FFFFFFC9] mt-8 block mx-auto"
          >
            Talk with coach
          </Link>

          <img
            className="mb-2 mt-16"
            src={RobotWithNote}
            alt="robot with note"
            loading="lazy"
          />
          <p className="font-semibold mb-2">AI Resume Reviewer</p>
          <p className="text-center">
            Use AI to help review your resume/cv in real time. Helping you point
            out mistakes and improve your chance at employment
          </p>
        </>
      )}
    </section>
  );
};
