import React from 'react';
import {
  PageContainer,
  PageHeading,
  Paragraph,
} from './styles';
import Interpretation from './Interpretation';
import PersonalData from './personalData';
import ChildrensPrivacy from './ChildrensPrivacy';
import WebsiteLinks from './WebsiteLinks';
import PrivacyChanges from './PrivacyChanges';

export default function PrivacyPolicy() {

  return (
    <>
      <PageContainer>
        <PageHeading>Privacy Policy</PageHeading>
        <Paragraph>Last updated: January 19, 2022</Paragraph>
        <Paragraph>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Paragraph>
        <Paragraph>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </Paragraph>

        {/* Page sections */}
        <Interpretation />
        <PersonalData />
        <ChildrensPrivacy />
        <WebsiteLinks />
        <PrivacyChanges />
      </PageContainer>
    </>
  );
}
