import React, { useRef } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { LiaPasteSolid } from 'react-icons/lia';
import { BsDownload } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import { FiFile } from 'react-icons/fi';
import { useUser } from '../contexts/user-context';

export const ResumeReviewUpload = () => {
  const resumeInputRef = useRef();
  const jobDescriptionFileRef = useRef();

  const {
    userResume,
    setUserResume,
    jobDescriptionText,
    setJobDescriptionText,
    setJobDescriptionFile,
    jobDescriptionFile,
    reviewResults,
    handleUserResumeChange,
    isValidUrl,
  } = useUser();


  const handleJobDescriptionFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    setJobDescriptionFile(e.target.files[0]);
    setJobDescriptionText(e.target.files[0].name);
  };

  const formatBytes = (bytes) => {
    if (bytes === 0) return '0B';

    const sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1000)); // Determines the unit
    const value = (bytes / 1000 ** i).toFixed(0);

    return `${value}${sizes[i]}`;
  };

  return (
    <>
      <div
        className={`border border-[#09CF82] rounded-lg min-h-[193px] md:min-h-[280px] mx-auto mb-7 overflow-hidden relative ${
          !userResume && 'flex items-center justify-center'
        }`}
      >
        <input
          type="file"
          accept=".pdf,.docx,.doc"
          className="hidden"
          ref={resumeInputRef}
          onChange={handleUserResumeChange}
          key={userResume ? 'file-selected' : 'file-not-selected'}
        />
        {userResume ? (
          <>
            <button
              className="absolute z-[1] top-2 right-2"
              type="button"
              onClick={() => setUserResume(null)}
            >
              <IoCloseOutline size={48} />
            </button>
            <img
              className="w-3/5  relative z-[-1]"
              src={URL.createObjectURL(userResume)}
              height="500px"
              alt="PDF Preview"
            />
            <div className="absolute z-[1] bottom-0 bg-[#F1F2F5] text-[#A7A9BC] w-full p-5 gap-5 flex items-center justify-between">
              <p className="break-words max-w-[200px]"> {userResume?.name}</p>
              <p>Size: {formatBytes(userResume?.size)}</p>
            </div>
          </>
        ) : (
          <button
            type="button"
            className="bg-[#BBBCBE] text-lg text-white flex gap-6 items-center py-1 px-5 m-auto rounded-md"
            onClick={(e) => {
              e.stopPropagation();
              resumeInputRef.current?.click();
            }}
          >
            Upload CV
            <FiFile size={24} className="scale-x-[-1]" />
          </button>
        )}
      </div>
      {!reviewResults && (
        <>
          <div className="text-[#09CF82] flex items-center gap-1">
            <AiFillInfoCircle size={20} />
            Optional
          </div>
          <div className="text-[#808080] mt-2">
            <p className="text-xl mb-2">Add Job Description</p>
            <label className="text-lg block">
              Place job link or job title to help the AI tune your review to
              your preferred role.
            </label>
            <div
              className={`border border-[#09CF82] rounded-xl flex items-center h-12 px-5 mt-2 ${
                jobDescriptionFile ? 'bg-gray-200' : ''
              }`}
            >
              <input
                className="block grow h-full focus:outline-none text-[22px] placeholder:text-[22px] disabled:cursor-not-allowed"
                placeholder="Paste link or document here"
                value={jobDescriptionText}
                onChange={(e) => setJobDescriptionText(e.target.value)}
                disabled={jobDescriptionFile}
              />
              <input
                type="file"
                accept=".pdf,.docx,.doc"
                className="hidden"
                ref={jobDescriptionFileRef}
                onChange={handleJobDescriptionFileChange}
                key={jobDescriptionFile ? 'file-selected' : 'file-not-selected'}
              />
              {jobDescriptionFile ? (
                <IoCloseOutline
                  size={30}
                  className="hover:cursor-pointer"
                  onClick={() => {
                    setJobDescriptionText('');
                    setJobDescriptionFile(null);
                  }}
                />
              ) : (
                <LiaPasteSolid
                  size={30}
                  className="hover:cursor-pointer"
                  onClick={() => jobDescriptionFileRef.current.click()}
                />
              )}
            </div>
            <p
              className={`mt-1 text-red-600 text-xs ${
                !isValidUrl(jobDescriptionText) &&
                jobDescriptionText.length &&
                !jobDescriptionFile
                  ? ''
                  : 'hidden'
              }`}
            >
              Invalid url
            </p>
          </div>
        </>
      ) }
    </>
  );
};

//       {reviewResults && <button type='button' className='border border-black text-xl rounded-xl px-5 py-2 w-full'>Download review</button>}
