import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  Container,
  InputBase,
  FormControl,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import apiClient from '../../../api/server';
import buildLogo from '../../../assets/images/home_icon_build.png';
// import searchLogo from '../../../assets/images/home_icon_search.png';
// import templateLogo from '../../../assets/images/home_icon_templates.png';

const _ = require('lodash');

const MainContent = styled.main`
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  .title {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100.5%;
    text-align: center;
    margin-bottom: 16px;
  }

  .description {
    font-family: 'Circular Std Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 57px;
  }

  .box-section {
    background: rgba(92, 112, 205, 0.04);
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 55px;
    height: 419px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .box-icon {
    max-width: 233px;
    margin-bottom: 18px;
  }

  .box-description {
    font-family: 'Circular Std Bold';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0em;
    color: #131936;
  }

  .box-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: 'Circular Std Book';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #5c70cd;
    align-items: center;
    justify-items: center;
  }

  .box-action {
    flex: 1;
  }

  .box-action-text {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .box-action-icon {
    margin-right: 15px;
  }

  .cover-letter-preview {
    width: 100%;
    text-align: center;
  }

  .cover-letter-image {
    width: 100%;
  }

  .form_next_btn {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background: #5c70cd;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0em;
    border: none;
    color: white;
    cursor: pointer;
  }

  .form-section {
    width: 50%;
    display: block;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 1024px) {
    .form-section {
      width: 100%;
      display: block;
      margin: 0 auto;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .form-container {
    width: 100%;
  }

  .input-label {
    text-align: left;
  }

  .text-align-center {
    text-align: center;
  }
`;

const CustomTextArea = styled(InputBase)(() => ({
  'label + &': {
    marginTop: 40,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F1F2F5',
    border: '1px solid #E7E7ED',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
  },
  fontFamily: ['Circular Std Regular'].join(','),
  '&:focus': {
    borderColor: 'rgba(92, 112, 205, 0.13)',
  },
}));

const CoverLetterReview = () => {
  const { user } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(true);
  const [image, setImage] = useState('');
  const [coverLetterData, setCoverLetterData] = useState({
    recipient: '',
    introduction: '',
    supportingEvidence: '',
    summary: '',
  });
  const buildSteps = [
    {
      step: 0,
      name: 'Recipient',
      fieldName: 'recipient',
      description: 'Who will be the recipient of this letter?',
      maxLength: 50,
      placeholder: 'Fill this space with the recipient name',
      tips: 'Your introduction should be telling the hiring manager who you are, a summary of your experience, why you should be hired for the role and make sure this ties to the job description of the role you’re applying for.',
    },
    {
      step: 1,
      name: 'Introduction',
      fieldName: 'introduction',
      description:
        'In 250 words, tell us a compelling summary of your experiences and interests.',
      maxLength: 250,
      placeholder: 'Fill in this space with an introduction that is compelling',
      tips: 'Your introduction should be telling the hiring manager who you are, a summary of your experience, why you should be hired for the role and make sure this ties to the job description of the role you’re applying for.',
    },
    {
      step: 2,
      name: 'Supporting Evidence',
      fieldName: 'supportingEvidence',
      description:
        'In 250 words, tell us a compelling summary of your experiences and interests.',
      maxLength: 250,
      placeholder: 'What have you done or achieved in this role in the past?',
      tips: 'Your evidence should show the hiring manager a history of achievements relevant to the job that you’re applying for. You don’t have to talk about all the jobs you’ve done. Simply demonstrate how your skills have yielded positive results in your previous roles.',
    },
    {
      step: 3,
      name: 'Summary',
      fieldName: 'summary',
      description:
        'In 250 words, tell us a compelling summary of your experiences and interests.',
      maxLength: 250,
      placeholder: 'Summarise all you have said in the first two paragraphs.',
      tips: 'Be polite in your summary. Express gratitude, restate your interest in the position and include a call-to-action i.e. a statement that shows the recruiter/hiring manager that you’re looking forward to the next steps.',
    },
    {
      step: 4,
      name: 'Signature',
      fieldName: 'signature',
      description: 'Add your signature',
      maxLength: 150,
      placeholder: 'Enter Signature.',
    },
  ];

  const validationSchema = yup.object({
    recipient: yup
      .string('Enter your recipient')
      .max(250, 'Recipient should be a max of 50 characters in length')
      .required('Recipient is required'),
    introduction: yup
      .string('Enter your introduction')
      .test({
        name: 'maxWords',
        test: (value) => value.split(/[ ,]+/).length <= 250,
        message: 'Introduction should be a max of 250 words in length',
      })
      .required('Introduction is required'),
    supportingEvidence: yup
      .string('Enter your supporting evidence')
      .test({
        name: 'maxWords',
        test: (value) => value.split(/[ ,]+/).length <= 250,
        message: 'Supporting evidence should be a max of 250 words in length',
      })
      .required('Supporting evidence is required'),
    summary: yup
      .string('Enter your summary')
      .test({
        name: 'maxWords',
        test: (value) => value.split(/[ ,]+/).length <= 250,
        message: 'Summary should be a max of 250 words in length',
      })
      .required('Summary is required'),
    signature: yup
      .string('Enter your signature')
      .test({
        name: 'maxWords',
        test: (value) => value && value.split(/[ ,]+/).length <= 150,
        message: 'Signature should be a max of 150 words in length',
      })
      .required('Signature is required'),
  });

  const getCoverLetterPreview = async () => {
    setIsDownloading(true);
    const response = await apiClient.getCoverLetterPreview(
      localStorage.getItem('userId')
    );
    if (response.status) {
      setImage(response.data);
      setIsDownloading(false);
    } else {
      console.log(response);
      setIsDownloading(false);
    }
  };

  const getCoverLetter = async () => {
    const response = await apiClient.getCoverLetter(
      localStorage.getItem('userId')
    );
    if (response.status) {
      setCoverLetterData({
        recipient: response.data.recipient,
        introduction: response.data.introduction,
        supportingEvidence: response.data.supportingEvidence,
        summary: response.data.summary,
        signature: response.data.signature,
      });
    } else {
      setCoverLetterData({
        recipient: '',
        introduction: '',
        supportingEvidence: '',
        summary: '',
        signature: '',
      });
    }
  };

  useEffect(() => {
    getCoverLetterPreview();
    getCoverLetter();
  }, []);

  const toggleEdit = () => {
    if (!isEditing === false) {
      getCoverLetterPreview();
      getCoverLetter();
    }

    setIsEditing(!isEditing);
  };

  const downloadURI = (base64, name) => {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('CriOS') > -1 ||
      navigator.vendor.toLowerCase().indexOf('google') > -1;
    let iOSVersion = [];
    if (iOS) {
      iOSVersion = navigator.userAgent
        .match(/OS [\d_]+/i)[0]
        .substr(3)
        .split('_')
        .map((n) => Number(n));
    }

    const attachmentData = base64;
    let contentType = 'application/pdf';

    const binary = atob(attachmentData.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    let hrefUrl = '';
    let blob = '';

    if (iOS && !isChrome && iOSVersion[0] <= 12) {
      blob = `data:application/pdf;base64,${attachmentData}`;
      hrefUrl = blob;
    } else {
      if (iOS && !isChrome) {
        contentType = 'application/octet-stream';
      }
      blob = new Blob([view], { type: contentType });
      hrefUrl = window.URL.createObjectURL(blob);
    }

    const a = document.createElement('a');

    a.setAttribute('href', hrefUrl);
    a.setAttribute('target', '_blank');
    if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
      a.setAttribute('download', name);
    }

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    a.dispatchEvent(clickEvent);
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    console.log(values);

    const model = _.cloneDeep(values);

    const response = await apiClient.createCoverLetter(
      localStorage.getItem('userId'),
      model
    );
    if (response.status) {
      toast.success(response.message);
      setSubmitting(false);
      getCoverLetterPreview();
      getCoverLetter();
      setIsEditing(false);
    } else {
      toast.error(response.message);
      setSubmitting(false);
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    const response = await apiClient.getCoverLetterPdf(
      localStorage.getItem('userId')
    );

    if (response.status) {
      const isMobile = window.matchMedia(
        'only screen and (max-width: 1024px)'
      ).matches;
      setIsDownloading(false);

      if (isMobile) {
        downloadURI(response.data.base64Data, 'coverletter.pdf');
      } else {
        window.open(response.data.url, '_blank');
      }

      toast.success('Template Selected successfully');
    } else {
      setIsDownloading(false);
      console.log(response);
    }
  };

  return (
    <MainContent>
      <Container id="content-section">
        {!isEditing && !isDownloading && (
          <Box className="cover-letter-preview">
            {image ? (
              <>
                <p className="title">Your Cover Letter is ready 🎉</p>
                <p className="description">
                  Read your cover letter below and edit as you wish
                </p>
                <button
                  type="submit"
                  onClick={toggleEdit}
                  className="form_next_btn inline"
                >
                  Edit
                </button>

                <img
                  src={image}
                  className="cover-letter-image"
                  alt="cover-letter"
                />

                <Box className="button-section">
                  {!isDownloading && (
                    <Box className="text-align-center">
                      <button
                        type="submit"
                        onClick={handleDownload}
                        className="form_next_btn inline"
                      >
                        Download
                      </button>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <>
                <p className="title">Cover Letter not created</p>
                <p className="description">Create one below</p>
                <Container id="content-section">
                  <Grid container spacing={6} sx={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/coverletter/build/recipient">
                        <Box className="box-section">
                          <img className="box-icon" src={buildLogo} alt="" />
                          <p className="box-description">
                            Build a new cover letter
                          </p>
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                </Container>
              </>
            )}
          </Box>
        )}
        {isDownloading && (
          <CircularProgress
            sx={{
              display: 'block',
              margin: '0 auto',
            }}
          />
        )}
        {isEditing && (
          <Box className="cover-letter-preview">
            <button
              type="submit"
              onClick={toggleEdit}
              className="form_next_btn inline"
            >
              Cancel Edit
            </button>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={coverLetterData}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {buildSteps.map((item) => (
                    <Box className="form-section" key={item.step}>
                      <p className="input-label">{item.name}</p>
                      <FormControl
                        className="form-container"
                        variant="standard"
                      >
                        <CustomTextArea
                          placeholder={item.placeholder}
                          id="custom-textarea-input"
                          multiline
                          rows={8}
                          value={values[item.fieldName]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={item.fieldName}
                        />
                        {errors[item.fieldName] && touched[item.fieldName] ? (
                          <div>{errors[item.fieldName]}</div>
                        ) : null}
                        {item.fieldName !== 'recipient' &&
                          values[item.fieldName] &&
                          values[item.fieldName].split(/[ ,]+/).length <
                            item.maxLength && (
                            <span className="words-left">
                              {item.maxLength -
                                values[item.fieldName].split(/[ ,]+/)
                                  .length}{' '}
                              words left
                            </span>
                          )}
                        {item.fieldName !== 'recipient' &&
                          values[item.fieldName] &&
                          values[item.fieldName].split(/[ ,]+/).length >=
                            item.maxLength && (
                            <span className="words-left">
                              Max word limit reached
                            </span>
                          )}
                        {item.fieldName === 'recipient' &&
                          values[item.fieldName] &&
                          values[item.fieldName].length < item.maxLength && (
                            <span className="words-left">
                              {item.maxLength - values[item.fieldName].length}{' '}
                              characters left
                            </span>
                          )}

                        {item.fieldName === 'recipient' &&
                          values[item.fieldName] &&
                          values[item.fieldName].length >= item.maxLength && (
                            <span className="words-left">
                              Max character limit reached
                            </span>
                          )}
                      </FormControl>
                    </Box>
                  ))}
                  <Box className="button-section">
                    {!isSubmitting && (
                      <Box className="text-align-center">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="form_next_btn inline"
                        >
                          Submit
                        </button>
                      </Box>
                    )}
                    {isSubmitting && <CircularProgress />}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </Container>
    </MainContent>
  );
};

export default CoverLetterReview;
