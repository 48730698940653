import React from 'react';
import { Box } from '@mui/material';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Welcome from './Welcome';
import Featured from './Featured/index';
import CareerPath from './CareerPath';
import OtherResources from './OtherResources';

export default function index() {
  return (
    <Box id="h-wrapper">
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', position: 'relative' }}>
        <Navbar />
        <Welcome />
        <Featured />
        <CareerPath />
      </Box>
    </Box>
  );
}
